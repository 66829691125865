@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

.Header {
  font-family: "Quicksand", sans-serif !important;
  background-color: transparent !important;
  // min-height: 100px;
  position: sticky;
  top: -100px;
  transition: top 0.3s ease-in-out;

  .HeaderItems {
    display: flex;
    flex-direction: row;
    flex: 1;
    margin-right: 17px !important;
    .LogoHeader,
    button {
      // margin-top: 240px;
    }
  }
  .Profile {
    // background-color: #1666b0;
    background-color: #fff;
    color: #58979d;
  }
}
