.overlay-active {
  overflow: hidden;
}

.blackDisplay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Opacità del 50% */
  z-index: 999; /* Imposta un valore più alto dello z-index del menu */
}

.navbar-smart {
  display: none !important;
}

.offcanvas-backdrop.show {
  background-color: transparent !important;
  opacity: 0 !important;
}

.navbar {
  background-color: #fff !important;
}

.navbar-toggler:focus {
  box-shadow: none !important;
  border: none !important;
}

.navbar-toggler {
  border: none !important;
}

.button-navbar {
  width: 100px !important;
  height: 30px !important;
  background-color: #1666b0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.button-navbar:hover {
  width: 100px !important;
  background-color: #10518a !important;
}

.button-navbar-denuncia {
  width: 100px !important;
  height: 30px !important;
  background-color: #1666b0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.button-navbar-denuncia:hover {
  width: 100px !important;
  background-color: #10518a !important;
}

.button-navbar-consulenza {
  width: 100px !important;
  height: 30px !important;
  background-color: #58979d !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.button-navbar-consulenza:hover {
  width: 100px !important;
  background-color: #477e75 !important;
}

.button-navbar-aziende {
  width: 100px !important;
  height: 30px !important;
  background-color: #473c90 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.button-navbar-aziende:hover {
  width: 100px !important;
  background-color: #352e6a !important;
}

.button-navbar-smart {
  width: 100px !important;
  height: 30px !important;
  background-color: #58979d !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-right: 50px !important;
}

.button-navbar-smart:hover {
  width: 100px !important;
  background-color: #352e6a !important;
}

.button-navbar-smart-denuncia {
  width: 100px !important;
  height: 30px !important;
  background-color: #1666b0 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-right: 50px !important;
}

.button-navbar-smart-denuncia:hover {
  width: 100px !important;
  background-color: #10518a !important;
}

.button-navbar-smart-consulenza {
  width: 100px !important;
  height: 30px !important;
  background-color: #58979d !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-right: 50px !important;
}

.button-navbar-smart-consulenza:hover {
  width: 100px !important;
  background-color: #477e75 !important;
}

.button-navbar-smart-aziende {
  width: 100px !important;
  height: 30px !important;
  background-color: #473c90 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-right: 50px !important;
}

.button-navbar-smart-aziende:hover {
  width: 100px !important;
  background-color: #352e6a !important;
}

.text-link-dark {
  position: relative !important;
  transition: all 0.5s !important;
  color: #20305b !important;
  margin-left: 10px !important;
}
.text-link-dark::after {
  content: "" !important;
  position: absolute !important;
  left: 0 !important;
  bottom: -2px !important;
  width: 100% !important;
  height: 3px !important;
  background-color: transparent !important;
  transition: all 0.3s !important;
}
.text-link-dark:hover::after {
  background-color: #20305b !important;
}
.text-link-dark:hover {
  color: #20305b !important;
}
.icon-hamburger {
  height: 3px !important;
  width: 25px !important;
  background-color: #20305b !important;
  display: block !important;
  margin-top: 5px !important;
}
.offcanvas {
  // background-color: #473c90 !important;
  background: linear-gradient(
    180deg,
    rgba(70, 82, 150, 1) 0%,
    rgba(74, 127, 176, 1) 76%,
    rgba(70, 82, 150, 1) 89%
  );
  width: 60% !important;
}
.show .icon-hamburger {
  background-color: #ffffff !important;
}

.navbar-toggler-icon {
  background-image: none !important;
}

@media (max-width: 992px) {
  .text-link-dark:hover::after {
    background-color: transparent !important;
  }
  .text-link-dark {
    color: #ffffff !important;
    font-weight: 500 !important;
  }
  .text-link-dark:hover {
    color: #ffffff !important;
    font-weight: 500 !important;
  }
  .navbar-pc {
    display: none !important;
  }
  .navbar-smart {
    display: block !important;
  }
}

@media (max-width: 500px) {
  .button-navbar-smart {
    width: 100px !important;
    margin-right: 20px !important;
  }
}

@media (max-width: 430px) {
  .button-navbar-smart-denuncia {
    margin-right: 20px !important;
    font-size: 14px !important;
  }

  .button-navbar-smart-consulenza {
    margin-right: 20px !important;
    font-size: 14px !important;
  }

  .button-navbar-smart-aziende {
    margin-right: 20px !important;
    font-size: 14px !important;
  }
}

@media (max-width: 400px) {
  .button-navbar-smart {
    width: 80px !important;
    margin-right: 10px !important;
  }
}
