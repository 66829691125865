.container-section-1-sectionText {
  margin-top: 27%;
}
.padding-sectionText {
  padding-left: 10px;
  padding-right: 10px;
}

// image
.cover-image {
  background: url(../img/sign.svg) no-repeat !important;
  background-size: cover !important;
  background-blend-mode: overlay !important;
  width: 560px !important;
  height: 600px !important;
  margin-left: 0px !important;
}

#lottie-animation {
  position: relative !important;
  top: -30% !important;
  left: 30% !important;
  transform: translateY(50%) !important;
}

// text
.title-hero-denuncia {
  font-size: 54px !important;
  font-weight: 600 !important;
}
.subtitle-hero-denuncia {
  font-size: 54px !important;
  font-weight: 700 !important;
}

.paragraf-section-2 {
  width: 90%;
  font-size: 18px !important;
  font-weight: 400 !important;
}

// media title
@media (max-width: 1024px) {
  .title-hero-denuncia {
    font-size: 48px !important;
  }
  .subtitle-hero-denuncia {
    font-size: 48px !important;
  }
}

@media (max-width: 768px) {
  .title-hero-denuncia {
    font-size: 36px !important;
  }
  .subtitle-hero-denuncia {
    font-size: 36px !important;
  }
}

// button
.btn-section-2 {
  margin-top: 20px;
  width: 200px !important;
  padding: 10px !important;
  font-weight: bold !important;
  background: linear-gradient(
    84deg,
    rgba(68, 59, 137, 1) 0%,
    rgba(75, 133, 180, 1) 87%
  ) !important;
  color: #ffffff !important;
  border-radius: 0px 20px 20px 0px !important;
  font-size: 18px !important;
  display: flex !important;
  justify-content: center !important;
}
.btn-section-2:hover {
  color: #ffffff !important;
}

@media (max-width: 1180px) {
  .cover-image {
    width: 460px !important;
    height: 495px !important;
    margin-left: 0px !important;
  }
}
@media (max-width: 900px) {
  .container-animation {
    display: flex !important;
    justify-content: center !important;
  }
  .container-text {
    margin-top: -100px !important;
  }
}
@media (max-width: 650px) {
  .cover-image {
    width: 310px !important;
    height: 330px !important;
    margin-left: 0px !important;
  }
}
@media (max-width: 1040px) {
  .container-section-1-sectionText {
    margin-top: 13%;
  }
}
@media (max-width: 970px) {
  .padding-sectionText {
    padding-left: 50px;
  }
}
@media (max-width: 970px) {
  .container-section-1-sectionText {
    margin-top: 70px;
  }
}
@media (max-width: 700px) {
  .padding-sectionText {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 400px) {
  .container-section-1-sectionText {
    margin-top: 40px;
  }
  #lottie-animation {
    left: 10% !important;
  }
}
@media (max-width: 340px) {
  #lottie-animation {
    left: 10% !important;
  }
}
@media (max-width: 320px) {
  #lottie-animation {
    left: 0% !important;
  }
}
