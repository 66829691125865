.bg-section-7 {
  width: 90%;
  background: linear-gradient(
    84deg,
    rgba(68, 59, 137, 1) 0%,
    rgba(75, 134, 180, 1) 100%
  );
  float: left;
  border-radius: 0px 300px 300px 0px;
  padding: 40px !important;
}

.title-infoHome {
  font-size: 30px !important;
  font-weight: 600 !important;
  text-align: center;
}

.img-trustPilot-denunce {
  display: inline-block;
  width: 150px;
  height: 150px;
  background: url(../../../src/assets/img/Trustpilot.png) no-repeat center
    center;
  background-size: contain;
  margin-bottom: 10px;
}

@media (max-width: 1024px) {
  .title-infoHome {
    font-size: 26px !important;
    font-weight: 600 !important;
  }
}

@media (max-width: 768px) {
  .title-infoHome {
    font-size: 22px !important;
    font-weight: 600 !important;
  }
}

.bg-sectionInfoHome-aziende {
  width: 90%;
  background: linear-gradient(
    84deg,
    rgba(32, 48, 91, 1) 0%,
    rgba(50, 54, 120, 1) 20%,
    rgba(71, 60, 144, 1) 100%
  ) !important;
  float: left;
  border-radius: 0px 300px 300px 0px;
  padding: 40px !important;
}

.bg-section-consulenza {
  width: 90%;
  background: linear-gradient(
    84deg,
    rgba(75, 133, 180, 1) 0%,
    rgb(79, 164, 168) 97%
  ) !important;
  float: left;
  border-radius: 0px 300px 300px 0px;
  padding: 40px !important;
}
.box-contatti {
  margin-left: 2%;
  margin-top: 80px !important;
}
.input-contact {
  border-radius: 20px;
  height: 45px;
}
.btn-contact {
  width: 140px;
}
.btn-contact {
  background-color: #ffffff;
  color: #1666b0;
  width: 140px;
  height: 40px;
  border-radius: 20px;
}
.btn-contact:hover {
  background-color: #ffffff;
  color: #1666b0;
}
.container-animation-three {
  width: 500px;
  height: 500px;
  position: absolute;
  top: 60px;
  left: 70%;
  overflow: hidden;
}
.container-animation-counter {
  width: 70%;
  height: 40%;
  display: flex;
  margin-left: 18%;
  margin-top: 20px;
  justify-content: center;
}
.info-personal-data {
  color: #ffffff;
  font-weight: 500;
  font: 24px;
}
.title-section-counter {
  font-size: 48px;
  font-weight: bold;
}
.text-section-counter {
  font-size: 20px;
  font-weight: 500;
}
.text-minor-section-counter {
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  font-weight: 500;
}
.container-secondary {
  height: 460px;
  display: flex;
  justify-content: none;
  // margin-left: 30px;
}
.containerTrustpilot {
  display: flex;
  justify-content: center;
  margin-top: -60px;
}
.containerSlider {
  margin-top: 40px !important;
  width: 100%;
  margin: auto;
}

.containerAction {
  display: flex;
  justify-content: center;
}

@media (max-width: 1300px) {
  .container-animation-three {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 80px;
    left: 39%;
    overflow: hidden;
  }
}
@media (max-width: 1000px) {
  .container-animation-three {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 73px;
    left: 37%;
    overflow: hidden;
  }
}
@media (max-width: 1180px) {
  .bg-section-7 {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      84deg,
      rgba(68, 59, 137, 1) 0%,
      rgba(75, 134, 180, 1) 100%
    );
    border-radius: 0px;
    // padding-bottom: 63%;
  }

  .bg-sectionInfoHome-aziende {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      84deg,
      rgba(32, 48, 91, 1) 0%,
      rgba(50, 54, 120, 1) 20%,
      rgba(71, 60, 144, 1) 100%
    ) !important;
    border-radius: 0px;
    // padding-bottom: 63%;
  }

  .bg-section-consulenza {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      84deg,
      rgba(75, 133, 180, 1) 0%,
      rgb(79, 164, 168) 97%
    ) !important;
    border-radius: 0px;
    // padding-bottom: 63%;
  }
}
@media (max-width: 992px) {
  .bg-section-7 {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      84deg,
      rgba(68, 59, 137, 1) 0%,
      rgba(75, 134, 180, 1) 100%
    );
    border-radius: 0px;
    // padding-bottom: 63%;
  }

  .bg-sectionInfoHome-aziende {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      84deg,
      rgba(32, 48, 91, 1) 0%,
      rgba(50, 54, 120, 1) 20%,
      rgba(71, 60, 144, 1) 100%
    ) !important;
    border-radius: 0px;
    // padding-bottom: 63%;
  }

  .bg-section-consulenza {
    width: 100%;
    height: 100%;
    background: linear-gradient(
      84deg,
      rgba(75, 133, 180, 1) 0%,
      rgb(79, 164, 168) 97%
    ) !important;
    border-radius: 0px;
    // padding-bottom: 63%;
  }
  .title-section-7 {
    font-size: 44px;
  }
  .subtitle-section-7 {
    font-size: 18px;
    width: 100% !important;
  }
  .box-input-row {
    display: inline-block;
    width: 350px;
    margin-top: 20px;
  }
  .box-privacy {
    width: 350px !important;
  }
  .text-info-personal-data {
    font-size: 12px !important;
  }
  .input-first {
    width: 115% !important;
  }
  .container-animation-three {
    display: none;
  }
  .box-privacy p {
    font-size: 12px !important;
  }
}
@media (max-width: 650px) {
  .containerSlider {
    margin-top: 30px !important;
  }
}
@media (max-width: 580px) {
  .container-animation-counter {
    width: 30%;
    display: flex;
    margin-left: 36%;
    margin-top: 20px;
    justify-content: center;
  }
  .title-section-counter {
    font-size: 34px;
  }
  .text-section-counter {
    font-size: 16px;
  }
  .text-minor-section-counter {
    font-size: 16px;
  }
  .box-contatti {
    margin-top: 40px !important;
    margin-left: 0%;
  }
  .containerTrustpilot {
    margin-top: -70px;
  }
  .containerAction {
    flex-direction: column;
    justify-content: center;
  }
  .marginButton {
    margin-top: 20px;
  }
}
