.container-sectionThree-aziende {
  position: relative;
  margin-bottom: 220px;
  display: flex;
  margin-top: 80px !important;
}

.container-animation-sectionThree-aziende {
  width: 500px;
  height: 500px;
  position: absolute;
  top: -50px;
  left: 120px;
}

@media (max-width: 1350px) {
  .container-animation-sectionThree-aziende {
    width: 500px;
    height: 500px;
    position: absolute;
    top: 100;
    left: 80px;
  }
}

@media (max-width: 1200px) {
  .container-animation-sectionThree-aziende {
    width: 500px;
    height: 500px;
    position: absolute;
    top: 100;
    left: 30px;
  }
}

.title-sectionThree-aziende {
  font-size: 48px !important;
  font-weight: 600 !important;
}

.subtitle-sectionThree-aziende {
  font-size: 30px !important;
}

@media (max-width: 1024px) {
  .title-sectionThree-aziende {
    font-size: 42px !important;
    font-weight: 600 !important;
  }
  .subtitle-sectionThree-aziende {
    font-size: 26px !important;
  }
}

@media (max-width: 768px) {
  .title-sectionThree-aziende {
    font-size: 36px !important;
    font-weight: 600 !important;
  }
  .subtitle-sectionThree-aziende {
    font-size: 22px !important;
  }
}

.icon1-aziende {
  background: url(../../img/online-banking.png) no-repeat;
  background-size: contain;
  height: 60px;
  width: 60px;
}

.icon2-aziende {
  background: url(../../img/claim.png) no-repeat;
  background-size: contain;
  height: 60px;
  width: 60px;
}

.icon3-aziende {
  background: url(../../img/select.png) no-repeat;
  background-size: contain;
  height: 60px;
  width: 60px;
}

.icon4-aziende {
  background: url(../../img/bus-stop.png) no-repeat;
  background-size: contain;
  height: 60px;
  width: 60px;
}

.btn-sectionThree-aziende {
  background: linear-gradient(
    84deg,
    rgba(32, 48, 91, 1) 0%,
    rgba(50, 54, 120, 1) 20%,
    rgba(71, 60, 144, 1) 100%
  ) !important;
  width: 200px;
  height: 50px;
  padding: 11px;
  font-weight: bold;
  border-radius: 0px 20px 20px 0px;
  color: #ffffff;
  font-size: 18px !important;
  display: flex !important;
  justify-content: center !important;
}

.btn-sectionThree-aziende:hover {
  color: #ffffff;
}

.container-animation-smart-sectionThree-aziende {
  display: none;
}

@media (min-width: 992px) and (max-width: 1080px) {
  .col-custom-sectionThree-aziende {
    width: 90% !important;
  }
}
@media (max-width: 1080px) {
  .container-sectionThree-aziende {
    margin-top: 0px !important;
    display: inline-block;
    margin-bottom: 180px !important;
  }

  .container-animation-sectionThree-aziende {
    display: none;
  }

  .container-animation-smart-sectionThree-aziende {
    width: 300px;
    height: 300px;
    // position: absolute;
    // top: 500;
    // left: 20px;
    display: block;
  }

  .box-sectionThree-aziende {
    padding: 30px;
    margin-top: 20px;
  }
  .box3-sectionThree-aziende {
    margin-top: 50px;
  }
  .subtitle-section-4 {
    font-size: 18px;
  }
  .text-paragraf {
    font-size: 14px;
  }
  .margin-bottom-5 {
    margin-bottom: 20px !important;
  }
}

@media (max-width: 900px) {
  .container-sectionThree-aziende {
    margin-bottom: 120px !important;
  }
}

@media (max-width: 500px) {
  .container-sectionThree-aziende {
    margin-bottom: 120px !important;
  }
}

@media (max-width: 400px) {
  .box-sectionThree-aziende {
    padding: 30px;
    margin-top: 20px;
  }
  .container-sectionThree-aziende {
    margin-bottom: 200px !important;
  }
}
