.Divider {
    p{
        color: #999;
        width: 100%;
        text-align: center;
        border-bottom: 1px solid #999;
        line-height: 0.1em;
        margin: 10px 0 20px;
        font-size: 0.875rem;
        
        span{
            background: #ececec;
            padding: 0 30px;
        }
    }
}
