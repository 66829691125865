.container-sectionTwo-aziende {
  width: 95% !important;
  background: linear-gradient(
    84deg,
    rgba(32, 48, 91, 1) 0%,
    rgba(50, 54, 120, 1) 20%,
    rgba(71, 60, 144, 1) 100%
  ) !important;
  border-radius: 0px 450px 450px 0px !important;
  position: relative !important;
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}

.box-sectionTwo-aziende {
  padding-top: 60px !important;
  margin-left: 100px !important;
}

.title-sectionTwo-aziende {
  font-size: 48px !important;
  font-weight: 600 !important;
}

.subtitle-sectionTwo-aziende {
  font-size: 22px !important;
}

@media (max-width: 1024px) {
  .title-sectionTwo-aziende {
    font-size: 42px !important;
    font-weight: 600 !important;
  }
  .subtitle-sectionTwo-aziende {
    font-size: 20px !important;
  }
}

@media (max-width: 768px) {
  .title-sectionTwo-aziende {
    font-size: 36px !important;
    font-weight: 600 !important;
  }
  .subtitle-sectionTwo-aziende {
    font-size: 18px !important;
  }
}

.box-text-sectionTwo-aziende {
  margin-top: 0px !important;
  margin-left: 100px !important;
}

.bubbles-container-aziende {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  margin-top: 50px !important;
}

.bubble-aziende {
  // 80
  width: 50px !important;
  height: 50px !important;
  border-radius: 50% !important;
  background-color: #ffffff !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-bottom: 60px !important;
  position: relative !important;
}

.bubble-aziende::before {
  content: "" !important;
  position: absolute !important;
  top: -35% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  height: 80px !important;
  color: #ffffff !important;
}

.bubbles-container-aziende .first-bubble-aziende::before {
  display: none !important;
}

.bubbles-container-aziende .second-bubble-aziende::before {
  content: "" !important;
  position: absolute !important;
  top: -60% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  height: 87px !important;
  color: #ffffff !important;
  border: 1px solid #ffffff !important;
  border-style: dashed !important;
}

.bubbles-container-aziende .three-bubble-aziende::before {
  content: "" !important;
  position: absolute !important;
  top: -60% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  height: 70px !important;
  color: #ffffff !important;
  border: 1px solid #ffffff !important;
  border-style: dashed !important;
}

.bubble-text-aziende {
  font-size: 32px !important;
  color: rgba(71, 60, 144, 1) !important;
  font-weight: 500 !important;
}

.text-title-bubble {
  font-size: 24px !important;
  color: #ffffff !important;
  font-weight: 600 !important;
}

.subtitle-title-bubble-sectionTwo-aziende {
  font-size: 18px !important;
  color: #ffffff !important;
  font-weight: 400 !important;
}

.button-sectionTwo-aziende {
  background-color: #ffffff !important;
  color: #1666b0 !important;
  width: 200px !important;
  height: 50px !important;
  font-weight: bold !important;
  padding: 11px !important;
  border-radius: 0px 20px 20px 0px !important;
  font-size: 18px !important;
  display: flex !important;
  justify-content: center !important;
}

.button-sectionTwo-aziende:hover {
  background-color: #ffffff !important;
  color: rgba(71, 60, 144, 1) !important;
}

.box-text-sectionTwo-aziende {
  margin-left: 50px !important;
  width: 70% !important;
  margin-top: -5px !important;
}

.box-button-sectionTwo-aziende {
  display: flex !important;
  // justify-content: start !important;
  margin-top: 30px !important;
  // margin-left: 100px !important;
}

.box-button-SectionTwo-aziende {
  margin-right: 150px !important;
}

.linkFaq {
  font-weight: 600;
  padding-top: 10px !important;
  font-size: 16px;
  color: #fff;
}

@media (max-width: 400px) {
  .linkFaq {
    margin-left: 20px !important;
  }
}

@media (max-width: 1300px) {
}

@media (max-width: 1215px) {
  .bubbles-container-aziende .three-bubble-aziende::before {
    content: "" !important;
    position: absolute !important;
    top: -80% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: 80px !important;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-style: dashed !important;
  }
}

@media (max-width: 1180px) {
  .container-sectionTwo-aziende {
    width: 100% !important;
    margin-top: 100px !important;
    background: linear-gradient(
      84deg,
      rgba(32, 48, 91, 1) 0%,
      rgba(50, 54, 120, 1) 20%,
      rgba(71, 60, 144, 1) 100%
    ) !important;
    position: relative !important;
    border-radius: 0px !important;
    height: 100% !important;
    margin-bottom: 0px !important;
  }

  .img-section-3-aziende {
    display: none;
  }

  .box-sectionTwo-aziende {
    padding-top: 40px !important;
    margin-left: 30px !important;
    width: 80% !important;
  }
  .bubbles-container-aziende {
    margin-top: 30px !important;
  }

  .title-sectionTwo-aziende {
    margin-top: 20px !important;
    margin-left: 20px !important;
  }

  .subtitle-sectionTwo-aziende {
    margin-left: 20px !important;
  }

  .bubble-aziende {
    border-radius: 50% !important;
    background-color: #ffffff !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-bottom: 40px !important;
    position: relative !important;
    margin-left: 20px !important;
  }

  .bubbles-container-aziende .second-bubble-aziende::before {
    content: "" !important;
    position: absolute !important;
    top: -45% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: 45px !important;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-style: dashed !important;
  }
  .bubbles-container-aziende .three-bubble-aziende::before {
    content: "" !important;
    position: absolute !important;
    top: -40% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: 40px !important;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-style: dashed !important;
  }

  .last-bubble-aziende::before {
    height: 215px !important;
    overflow: hidden !important;
  }

  .bubble-text-aziende {
    font-size: 32px !important;
    color: rgba(71, 60, 144, 1) !important;
    font-weight: 500 !important;
  }

  .bubble-text-consulenza {
    font-size: 32px !important;
    font-weight: 500 !important;
  }

  .text-title-bubble {
    font-size: 16px !important;
  }

  .subtitle-title-bubble-sectionTwo-aziende {
    font-size: 14px !important;
  }

  .box-text-sectionTwo-aziende {
    margin-left: 20px !important;
    width: 70% !important;
  }

  .box-button-SectionTwo-aziende {
    margin-right: 20px !important;
  }
}

@media (max-width: 780px) {
  .container-sectionTwo-aziende {
    height: 100% !important;
  }
}

@media (max-width: 984px) {
  .bubbles-container-aziende .three-bubble-aziende::before {
    content: "" !important;
    position: absolute !important;
    top: -53% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: 50px !important;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-style: dashed !important;
  }
}

@media (max-width: 800px) {
  .box-button-sectionTwo-aziende {
    text-align: center;
  }
  .box-button-sectionTwo-aziende a {
    display: inline-block;
  }
}

@media (max-width: 670px) {
  .container-sectionTwo-aziende {
    width: 100% !important;
    margin-top: 30px !important;
    background: linear-gradient(
      84deg,
      rgba(32, 48, 91, 1) 0%,
      rgba(50, 54, 120, 1) 20%,
      rgba(71, 60, 144, 1) 100%
    ) !important;
    position: relative !important;
    border-radius: 0px !important;
    height: 100% !important;
    margin-bottom: 0px !important;
  }

  .box-sectionTwo-aziende {
    padding-top: 40px !important;
    margin-left: 20px !important;
    width: 80% !important;
  }
  .bubbles-container-aziende {
    margin-top: 30px !important;
  }

  .title-sectionTwo-aziende {
    margin-top: 20px !important;
    margin-left: 20px !important;
  }

  .bubble-aziende {
    border-radius: 50% !important;
    background-color: #ffffff !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-bottom: 40px !important;
    position: relative !important;
    margin-left: 20px !important;
  }
  .bubbles-container-aziende .second-bubble-aziende::before {
    content: "" !important;
    position: absolute !important;
    top: -51% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: 47px !important;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-style: dashed !important;
  }
  .bubbles-container-aziende .three-bubble-aziende::before {
    content: "" !important;
    position: absolute !important;
    top: -53% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: 50px !important;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-style: dashed !important;
  }
  .last-bubble-aziende::before {
    height: 215px !important ;
    overflow: hidden !important ;
  }

  .bubble-text-aziende {
    font-size: 32px !important;
    color: rgba(71, 60, 144, 1) !important;
    font-weight: 500 !important;
  }

  .text-title-bubble {
    font-size: 16px !important;
  }
  .subtitle-title-bubble-sectionTwo-aziende {
    font-size: 14px !important;
  }
  .box-text-sectionTwo-aziende {
    margin-left: 20px !important;
    width: 70% !important;
  }
  .box-button-SectionTwo-aziende {
    margin-right: 20px !important;
  }
  .box-button-sectionTwo-aziende {
    display: flex !important;
    // justify-content: center !important;
    margin-top: 20px !important;
  }
}

@media (max-width: 668px) {
  .bubbles-container-aziende .three-bubble-aziende::before {
    content: "" !important;
    position: absolute !important;
    top: -44% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: 60px !important;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-style: dashed !important;
  }
}

@media (max-width: 498px) {
  .bubbles-container-aziende .three-bubble-aziende::before {
    content: "" !important;
    position: absolute !important;
    top: -55% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: 55px !important;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-style: dashed !important;
  }

  .bubbles-container-aziende .second-bubble-aziende::before {
    content: "" !important;
    position: absolute !important;
    top: -63% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: 77px !important;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-style: dashed !important;
  }
}

// @media (max-width: 472px) {
//   .bubbles-container-aziende .second-bubble-aziende::before {
//     content: "" !important;
//     position: absolute !important;
//     top: -63% !important;
//     left: 50% !important;
//     transform: translate(-50%, -50%) !important;
//     height: 60px !important;
//     color: #ffffff !important;
//     border: 1px solid #ffffff !important;
//     border-style: dashed !important;
//   }
// }

@media (max-width: 446px) {
  .bubbles-container-aziende .three-bubble-aziende::before {
    content: "" !important;
    position: absolute !important;
    top: -50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: 50px !important;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-style: dashed !important;
  }
}
@media (max-width: 415px) {
  .bubbles-container-aziende .three-bubble-aziende::before {
    content: "" !important;
    position: absolute !important;
    top: -60% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: 70px !important;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-style: dashed !important;
  }
}
@media (max-width: 351px) {
  .bubbles-container-aziende .three-bubble-aziende::before {
    content: "" !important;
    position: absolute !important;
    top: -105% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: 100px !important;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-style: dashed !important;
  }
}
@media (max-width: 600px) {
  .box-button-sectionTwo-aziende {
    margin-bottom: 20px !important;
  }
  .link-faq-margin {
    margin-bottom: 30px !important;
    margin-left: 90px !important;
  }
  .box-button-SectionTwo-aziende {
    display: flex !important;
    margin-left: 90px !important;
  }
}
@media (max-width: 900px) {
  .container-sectionTwo-aziende {
    margin-top: 20px !important;
  }

  .box-button-sectionTwo-aziende {
    margin-left: 0px !important;
  }
}
@media (max-width: 410px) {
  .link-faq-margin {
    margin-left: 90px !important;
  }
}

// responsive grafica

.img-section-3-aziende {
  position: absolute;
  bottom: -5%;
  left: 70%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 400px;
  background: url(../../img/how_it_works.svg) no-repeat;
  background-size: cover;
  z-index: 10;
  transform: rotateY(180deg) !important;
  align-self: flex-end;
  margin-bottom: 0;
}

@media (max-width: 1700px) {
  .img-section-3-aziende {
    bottom: -4%;
  }
}
