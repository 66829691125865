// image
.cover-image-consulenza {
  background: url(../img/sign-consulenza.svg) no-repeat !important;
  background-size: cover !important;
  background-blend-mode: overlay !important;
  width: 560px !important;
  height: 600px !important;
  margin-left: 0px !important;
  transform: scaleX(-1) !important;
  background-position: center !important;
  max-width: 560px !important;
}

.margin-left-text {
  margin-left: 100px !important;
}

// text
.title-hero-consulenza {
  font-size: 54px !important;
  font-weight: 600 !important;
}
.subtitle-hero-consulenza {
  font-size: 54px !important;
  font-weight: 700 !important;
}

.paragraf-section-2 {
  width: 90%;
  font-size: 18px !important;
  font-weight: 400 !important;
}

// media title
@media (max-width: 1024px) {
  .title-hero-consulenza {
    font-size: 48px !important;
  }
  .subtitle-hero-consulenza {
    font-size: 48px !important;
  }
}

@media (max-width: 768px) {
  .title-hero-consulenza {
    font-size: 36px !important;
  }
  .subtitle-hero-consulenza {
    font-size: 36px !important;
  }
}

.btn-section-consulenza {
  margin-top: 20px;
  width: 200px !important;
  padding: 11px !important;
  font-weight: bold !important;
  background: linear-gradient(
    84deg,
    rgba(75, 133, 180, 1) 0%,
    rgb(79, 164, 168) 97%
  ) !important;
  color: #ffffff !important;
  border-radius: 0px 20px 20px 0px !important;
  font-size: 18px !important;
  display: flex !important;
  justify-content: center !important;
}
.btn-section-consulenza:hover {
  color: #ffffff !important;
}

#lottie-animation-consulenza {
  position: relative !important;
  top: -45% !important;
  left: 30% !important;
  transform: translateY(50%) !important;
}

@media (max-width: 1375px) {
  #lottie-animation-consulenza {
    left: 20% !important;
  }
}

@media (max-width: 1180px) {
  .cover-image-consulenza {
    width: 460px !important;
    height: 500px !important;
    max-width: 460px !important;
  }
  .margin-left-text {
    margin-left: 100px !important;
  }
}

@media (max-width: 1000px) {
  .margin-left-text {
    margin-left: 50px !important;
  }
  #lottie-animation-consulenza {
    left: 10% !important;
  }
}

@media (max-width: 900px) {
  .margin-left-text {
    margin-left: 0px !important;
  }
  #lottie-animation-consulenza {
    left: 20% !important;
  }
}

@media (max-width: 650px) {
  .cover-image-consulenza {
    width: 310px !important;
    height: 360px !important;
    max-width: 330px !important;
  }
}

@media (max-width: 400px) {
  #lottie-animation-consulenza {
    left: 10% !important;
  }
}

@media (max-width: 340px) {
  #lottie-animation-consulenza {
    left: 10% !important;
  }
}
@media (max-width: 320px) {
  #lottie-animation-consulenza {
    left: 0% !important;
  }
}
