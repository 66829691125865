// css blog detail

.title {
  margin-top: 20px;
  font-size: 36px !important;
  font-weight: 600;
  color: #1666b0;
  font-family: Quicksand, sans-serif !important;
}

.bg-section {
  width: 90%;
  height: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  background: linear-gradient(
    84deg,
    rgba(68, 59, 137, 1) 0%,
    rgba(75, 134, 180, 1) 100%
  );
  float: left;
  border-radius: 0px 300px 300px 0px;
}

.containerImageBlog {
  width: 50%;
  object-fit: cover;
}

.container-button-article-details {
  display: flex;
  justify-content: flex-start;
  margin-top: 50px;
}

.disabled-link {
  pointer-events: none !important;
  opacity: 0.5 !important;
  text-decoration: none !important;
}

.container-previous-article-icon {
  display: none !important;
}

.container-next-article-icon {
  display: none !important;
}

@media (max-width: 1100px) {
  .containerImageBlog {
    width: 70%;
  }
}

@media (max-width: 900px) {
  .containerImageBlog {
    width: 100%;
  }
}

@media (max-width: 1000px) {
  .bg-section {
    max-width: 100%;

    border-radius: 0px;
    float: none;
  }
  .container-animation-blog {
    width: 200px;
  }
  .container-animation-blog svg {
    margin-left: -20px;
  }
  .title-preFooter {
    font-size: 32px !important;
  }
  .text-preFooter {
    font-size: 14px !important;
  }
  .text-icon-preFooter {
    font-size: 12px !important;
    margin-top: 5px;
  }
}

.container-preFooter {
  margin-left: -60px;
}

.title-preFooter {
  color: #ffffff;
  font-size: 38px;
  font-weight: 600;
}

.text-preFooter {
  color: #ffffff;
  font-weight: 600;
}

.container-icon-preFooter {
  display: flex;
  margin-top: 10px;
}

.text-icon-preFooter {
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
  margin-top: 2px;
}

.container-animation-blog {
  margin-top: -45px;
}

.containerSmallIcon {
  display: flex;
  align-items: center;
}

.containerSmallIcon-2 {
  display: flex;
  align-items: center;
  margin-left: 40px !important;
}

.containerSmallIcon-3 {
  display: flex;
  align-items: center;
  margin-left: 20px !important;
  width: 100% !important;
}

.containerImage {
  width: 100%;
  overflow: hidden;
}

// testo nelle card

.textDescriptionCard span {
  font-family: Quicksand, sans-serif !important;
}

@media (max-width: 1200px) {
  .containerImage {
    width: 100%;
    height: 300;
    overflow: hidden;
  }
}

@media (max-width: 750px) {
  .bg-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .container-preFooter {
    margin-left: 0px !important;
  }
  .title-preFooter {
    margin-top: -30px !important;
    font-size: 28px !important;
  }
  .text-preFooter {
    font-size: 12px !important;
  }
  .text-icon-preFooter {
    font-size: 10px !important;
    margin-top: 5px;
  }
  .container-icon-preFooter {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }
  .containerSmallIcon {
    width: 100% !important;
    margin-bottom: 10px;
  }
  .containerSmallIcon-2 {
    margin-left: 0px !important;
    margin-bottom: 10px;
  }
  .containerSmallIcon-3 {
    margin-left: 0px !important;
  }
  .container-animation-blog {
    margin-left: 35px !important;
  }
  .btn-preFooter {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 600px) {
  .title {
    font-size: 24px !important;
  }

  h2 {
    font-size: 22px !important;
  }

  .container-previous-article {
    display: none !important;
  }

  .container-next-article {
    display: none !important;
  }

  .container-previous-article-icon {
    display: block !important;
  }

  .container-next-article-icon {
    display: block !important;
  }

  .container-button-article-details {
    margin-top: 50px !important;
  }

  .icon-previous {
    font-size: 24px !important;
    margin-right: 4px !important;
    border-bottom: 1px solid #20305b !important;
  }

  .icon-next {
    font-size: 24px !important;
    margin-left: 4px !important;
    border-bottom: 1px solid #20305b !important;
  }
}
