.TrustPilotCard {
  .TrustPilotCardContainer {
    height: 280px;
    overflow: hidden;
  }

  .svg-female {
    background: url(../img/avatar_female.svg.svg) no-repeat;
    width: 50px !important;
    height: 50px !important;
  }
  .svg-male {
    background: url(../img/avatar_male.svg.svg) no-repeat;
    width: 50px !important;
    height: 50px !important;
  }
  .starsTrustpilot {
    background-size: contain !important;
    background: url(../img/stars-trustpilot.svg) no-repeat;
    width: 100px !important;
    height: 20px !important;
    margin-top: 10px;
  }
  .textContentCardTrustpilot {
    margin-top: 10px;
    font-size: 14px;
    font-weight: 600;
    color: #20305b;
    text-align: center;
    height: 42px;
  }
  @media (max-width: 800px) {
    .textContentCardTrustpilot {
      font-size: 12px;
    }
  }
}
