// @import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");

/*General*/
body {
  font-family: "Quicksand", sans-serif;
  margin: 0;
  min-height: calc(100vh - 0px); // -150px
  background: linear-gradient(
    180deg,
    rgba(70, 82, 150, 1) 0%,
    rgba(74, 127, 176, 1) 46%,
    rgba(70, 82, 150, 1) 89%
  );
  background-size: auto;
  background-position: start;
  background-repeat: no-repeat;
  a,
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  button {
    font-family: "Quicksand", sans-serif !important;
  }
}

body[data-root="/"],
body[data-root="/faq"],
body[data-root="/consulenza"],
body[data-root="/cookie-policy"],
body[data-root="/privacy"],
body[data-root="/terms-conditions"],
body[data-root="/b"],
body[data-root="/aziende"] {
  background: #fff;
}
// colore background page blog-article
.blog-article-background {
  background: #fff !important;
}

// body[data-root="/register"],
// body[data-root="/login"] {
//   background: linear-gradient(
//       180deg,
//       rgba(70, 82, 150, 1) 0%,
//       rgba(74, 127, 176, 1) 46%,
//       rgba(70, 82, 150, 1) 89%
//     ),
//     url(./img/sign.svg);
//   background-size: auto;
//   background-position: start;
//   background-repeat: no-repeat;
// }

/* all input elements */

.font-primary {
  font-family: "Quicksand", sans-serif !important;
}

.MuiOutlinedInput-root {
  background: white;
  border-radius: 10px;
}
.MuiInputLabel-outlined.Mui-focused {
  color: #ffffff;
}

/* Pratica numero */

.ProductNumber {
  color: #fff;
  font-size: 22px;
  font-weight: 700 !important;
  span {
    // color: #1666b0;
    color: #fff;
  }
}

/*Form Builder*/

.lexiaFormContainer {
  margin-top: 3rem;
  text-align: left;
  h3 {
    font-weight: 600;
    font-size: 1rem !important;
    text-transform: uppercase;
  }
  .the-form {
    label {
      text-align: left;
    }
  }
}

/*  DropZone */

.DropArea {
  background-color: #fff;
  padding: 25px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  svg {
    fill: #1666b0;
  }
  p {
    font-size: 1.2rem;
    color: #1666b0;
    font-weight: 500;
  }
}

// dimensione img file caricato
.MuiDropzonePreviewList-image {
  height: 40px !important;
  margin-bottom: 8px;
}

.DropArea .MuiTypography-body1 {
  font-size: 14px !important;
}

.MuiDropzoneArea-root {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  // min-height: auto;
}
// nascondi popup post caricamneto doc
.MuiDropzoneSnackbar-successAlert {
  display: none !important;
}
// nascondi popup post caricamneto errore limite massimo doc
.MuiDropzoneSnackbar-errorAlert {
  display: none !important;
}
.MuiDropzoneSnackbar-infoAlert {
  display: none !important;
}

p.MuiFormHelperText-root {
  color: red;
}

// yousign custom style

iframe#yousign-iframe {
  width: 100%;
  height: 100vh;
  margin-bottom: 50px;
}

button.step-btn svg {
  display: none !important;
}

.color1 {
  color: #20305b;
}
.color2 {
  color: #1666b0;
}
.color3 {
  background-color: #473c90;
}
.color4 {
  color: #70c5c9;
}
.color5 {
  color: #4b85b4;
}

.color-purple {
  color: rgba(71, 60, 144, 1) !important;
}

// classe per footer in page cookie-privacy-terms

.footer-one-page {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.cookie-container {
  // max-height: 400px;
  overflow-y: auto;
}

// style for text

// box
.info-text-title {
  color: #20305b !important;
  font-size: 18px !important;
  font-weight: 600 !important;
}
.info-text-subtitle {
  color: #20305b !important;
  font-size: 14px !important;
}

.text-bold {
  font-weight: bold !important;
}

.title-info {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}
.subtitle-info {
  color: #fff;
  font-size: 16px;
}

// css doc pdf

.box-pdf {
  width: 852px !important;
  border-radius: 3px !important;
}
.pdf {
  width: 852px !important;
}

// classe error in form yousign

.redHelperText {
  color: #ffdfdf !important;
}

// css pdf
.pdf-canvas {
  border: 1px solid #ccc;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  height: auto;
  border-radius: 4px !important;
}

// box-yousign
.yousign-container {
  border-radius: 4px !important;
  overflow: hidden !important;
}

.trustpilot {
  margin-top: -25px !important;
  border-radius: 4px !important;
}

.trustpilot a {
  font-size: 18px !important;
  color: #fff !important;
  font-weight: 800 !important;
}

.trustpilotHome {
  display: flex;
  justify-content: center;
  margin-top: -45px !important;
}

.trustpilotHome a {
  font-size: 12px !important;
  color: #fff !important;
  font-weight: 500 !important;
}

// label chackbox modal
.label-font {
  font-size: 10px !important;
}

// colore checkbox
.css-zun73v.Mui-checked,
.css-zun73v.MuiCheckbox-indeterminate {
  color: #58979d !important;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  color: #20305b !important;
}
.MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root
  .MuiAutocomplete-inputRoot {
  padding: 5px !important;
  font-family: "Quicksand", sans-serif !important;
}
.MuiAutocomplete-option {
  color: #20305b !important;
  font-family: "Quicksand", sans-serif !important;
  font-weight: 500 !important;
}

.MuiOutlinedInput-root {
  border-radius: 4px !important;
}

// popup conferma in denuncia preventivo

.popup {
  position: fixed;
  bottom: 65px;
  border-radius: 10px;
  left: calc(50% - 175px);
  width: 350px;
  background-color: #fff;
  padding: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
  animation: slide-up 0.3s ease;
}

.popup-discarded {
  position: fixed;
  bottom: 65px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 35%;
  border-radius: 10px;
  background-color: #fff;
  padding: 25px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
  animation: slide-up 0.3s ease;
}

@media (max-width: 900px) {
  .popup-discarded {
    width: 50% !important;
  }
}

@media (max-width: 600px) {
  .popup-discarded {
    width: 70% !important;
  }
}

@media (max-width: 500px) {
  .popup {
    position: fixed;
    bottom: 100px;
    border-radius: 10px;
    left: calc(50% - 175px);
    width: 350px;
    background-color: #fff;
    padding: 20px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
    animation: slide-up 0.3s ease;
  }

  .popup-discarded {
    width: 90% !important;
  }
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.img-button-paypal {
  background: url(../assets/img/Paypal.svg) no-repeat;
  background-size: contain;
  width: 25%;
  margin-top: 10px;
  height: 28px;
}

// style per le card dentro la dashboard "Raccontaci il tuo caso" "Calendly"

.containerCard {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.containerCardPartner {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
}

.modal-partner-error {
  margin-top: 100px !important;
}

@media (max-width: 900px) {
  .cardTitleDenunceCustomer {
    text-decoration: underline !important;
  }

  .modal-partner-error {
    margin-top: 50px !important;
  }
}

.card {
  background-color: #fff;
  height: 100%;
  border-radius: 10px;
  padding-top: 30px;
  transition: 0.4s ease;
}
.card:hover {
  background-color: #ffffffdb;
}

.card-partner {
  background-color: #58979d;
  height: 100%;
  border-radius: 10px;
  padding-top: 30px;
  transition: 0.4s ease;
}
.card-partner:hover {
  background-color: #58979d;
  filter: brightness(0.9);
}

.cardTitleDenunceCustomer {
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  padding: 10px;
}

.cardTitleDenunceCustom {
  font-size: 22px;
  font-weight: bold;
  color: #1666b0;
  padding: 10px;
}

@media (max-width: 600px) {
  .card {
    height: 240px;
  }

  .cardTitleDenunceCustomer {
    width: 70%;
    font-size: 20px;
    text-decoration: underline !important;
  }

  .cardTitleDenunceCustom {
    font-size: 20px;
  }

  .containerCardPartner {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }
}

@media (max-width: 460px) {
  .cardTitleDenunceCustomer {
    width: 80%;
    font-size: 16px !important;
  }

  .cardTitleDenunceCustom {
    font-size: 18px !important;
  }
}

@media (max-width: 380px) {
  .card {
    height: 260px;
  }
}

.card-list-practices {
  background-color: #58979d;
  height: 100%;
  border-radius: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  transition: 0.4s ease;
}

.card-list-practices:hover {
  background-color: #58979d;
  filter: brightness(0.9);
}

.card-list-denunce {
  background-color: #fff;
  height: 100%;
  border-radius: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.card-list-denunce:hover {
  background-color: #ffffffdb;
}

.icon-home-dashoard {
  display: inline-block;
  width: 34px;
  height: 34px;
  background: url(../assets/img/icon-dashboard/Home.svg) no-repeat center center;
  background-size: contain;
}

.icon-back-dashoard {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: url(../assets/img/icon-dashboard/Back.svg) no-repeat center center;
  background-size: contain;
  margin-top: 5px;
}

.icon-error-message {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(../assets/img/icon-dashboard/warning_message.png) no-repeat
    center center;
  background-size: contain;
  margin-top: 5px;
}

.text-message-error {
  color: #fff;
  text-align: start;
  margin-left: 10px;
  margin-top: 9px;
}

@media (max-width: 500px) {
  .text-message-error {
    font-size: 14px !important;
    margin-left: 10px !important;
    width: 95% !important;
  }
}

.icon-back-dashoard-modal {
  display: inline-block;
  width: 30px;
  height: 30px;
  background: url(../assets/img/icon-dashboard/BackBlue.svg) no-repeat center
    center;
  background-size: contain;
}

.link-call-calendly {
  text-decoration: none;
  background-color: #58979d;
  border-radius: 10px;
  padding: 6px 8px;
  min-width: 64px;
  font-size: 14px;
  color: #fff;
  font-weight: 500;
  transition: 0.4s;
}

.link-update-call-calendly {
  text-decoration: none;
  background-color: transparent;
  border: 1px solid #20305b;
  border-radius: 10px;
  padding: 6px 8px;
  min-width: 64px;
  font-size: 14px;
  color: #20305b;
  font-weight: 500;
  transition: 0.4s;
  margin-right: 10px;
  cursor: pointer !important;
}

.link-update-call-calendly:hover {
  background-color: rgba(0, 0, 0, 0.1);
  opacity: 0.5;
}

.link-call-calendly:hover {
  background-color: #58979d;
  filter: brightness(0.9);
}

.cardTitle {
  font-size: 16px;
  color: #1666b0;
  font-weight: 700;
}

.cardText {
  font-size: 16px;
  color: #20305b;
  padding: 20px;
}

.cardTextPreventivo {
  font-size: 16px;
  color: #20305b;
  padding: 10px;
}

// icone dashboard

.icon-dashboard-denunce {
  display: inline-block;
  width: 50px;
  height: 50px;
  background: url(../assets/img/icon-dashboard/Edit.svg) no-repeat center center;
  background-size: contain;
  margin-bottom: 10px;
}

.icon-dashboard-consulenza {
  display: inline-block;
  width: 50px;
  height: 50px;
  background: url(../assets/img/icon-dashboard/CustomerSupport.svg) no-repeat
    center center;
  background-size: contain;
  margin-bottom: 10px;
}

.icon-dashboard-consulenza-card {
  display: inline-block;
  width: 50px;
  height: 50px;
  background: url(../assets/img/icon-dashboard/CustomerSupportCalendlyCard.svg)
    no-repeat center center;
  background-size: contain;
  margin-bottom: 10px;
}

.icon-dashboard-denunce-customer {
  display: inline-block;
  width: 50px;
  height: 50px;
  background: url(../assets/img/icon-dashboard/File-List.svg) no-repeat center
    center;
  background-size: contain;
  margin-bottom: 10px;
  margin-right: 0px;
}

.icon-dashboard-denunce-customer-partner {
  display: inline-block;
  width: 50px;
  height: 50px;
  background: url(../assets/img/icon-dashboard/File-List.svg) no-repeat center
    center;
  background-size: contain;
  margin-bottom: 10px;
}

.icon-dashboard-denunce-customer-card {
  display: inline-block;
  width: 50px;
  height: 50px;
  background: url(../assets/img/icon-dashboard/File-list-card.svg) no-repeat
    center center;
  background-size: contain;
}

.icon-dashboard-denunce-custom {
  display: inline-block;
  width: 50px;
  height: 50px;
  background: url(../assets/img/icon-dashboard/Help.svg) no-repeat center center;
  background-size: contain;
  margin-right: 20px;
}

.cardLink {
  color: #58979d;
  font-size: 16px;
  font-weight: 600;
  margin-top: 5px;
}

.cardLink:hover {
  background-color: transparent;
}

.iconModel {
  margin-right: 10px;
  color: #58979d;
  font-size: 38px;
  margin-top: -14px;
}

.iconCall {
  margin-right: 10px;
  color: #58979d;
  font-size: 32px;
  margin-top: -10px;
}

.containerCardDenunceUser {
  cursor: pointer;
  margin-bottom: 30px !important;
  margin-top: 0px !important;
}

.title-card-dashboard {
  font-size: 22px;
  font-weight: bold;
  color: #1666b0;
  text-align: center;
}

.containerCardSecondary {
  // margin-left: 30px;
}

@media (max-width: 900px) {
  .title-card-dashboard {
    text-decoration: underline !important;
  }
}

@media (max-width: 750px) {
  .containerCardSecondary {
    margin-bottom: 30px;
  }

  .containerCardDenunceUser {
    cursor: pointer;
    margin-top: 0px !important;
  }

  .cardTitle {
    font-size: 16px;
    color: #1666b0;
    font-weight: 700;
    margin-top: 5px;
  }
  .cardText {
    font-size: 14px;
    color: #20305b;
    padding: 20px;
  }
  .cardLink {
    color: #58979d;
    font-size: 16px;
    font-weight: 700;
    margin-top: 5px;
  }

  .iconModel {
    font-size: 32px;
    margin-top: -4px;
  }

  .iconCall {
    font-size: 28px;
    margin-top: 0px;
  }
}

@media (max-width: 600px) {
  .containerCard {
    justify-content: center;
  }

  .title-card-dashboard {
    font-size: 20px;
    text-decoration: underline !important;
  }

  .cardTitleDenunceCustomer {
    font-size: 20px;
  }

  .cardTitleDenunceCustom {
    font-size: 20px;
  }
}

@media (max-width: 450px) {
  .title-card-dashboard {
    font-size: 16px;
    text-decoration: underline !important;
  }
  .icon-dashboard-denunce-customer {
    margin-right: 0px;
    margin-left: 20px;
  }
  .icon-dashboard-denunce-custom {
    margin-right: 0px;
    margin-left: 20px;
  }
}

@media (max-width: 350px) {
  .title-card-dashboard {
    font-size: 16px;
  }
  .cardTitleDenunceCustomer {
    font-size: 16px !important;
  }
  .cardTitleDenunceCustom {
    font-size: 16px !important;
  }
}

// container login

.containerButton {
  justify-content: center;
}
.containerGoogle {
  margin-bottom: 20px !important;
}

.containerFacebook {
  margin-left: 40px !important;
}

@media (max-width: 600px) {
  .containerFacebook {
    margin-left: 0px !important;
  }
}

// codice custom per stile chat tidio in home

#tidio-chat iframe {
  bottom: 20px !important;
}

@media only screen and (max-width: 1200px) {
  #tidio-chat iframe {
    bottom: 60px !important;
  }
}
@media only screen and (max-width: 980px) {
  #tidio-chat iframe {
    bottom: 60px !important;
  }
}
@media only screen and (max-width: 600px) {
  #tidio-chat iframe {
    bottom: 60px !important;
  }
}

.accordion-suggestion-denunce {
  margin-bottom: 30px !important;
}

.modal-partner-error {
  margin-top: 100px !important;
}

@media (max-width: 900px) {
  .modal-partner-error {
    margin-top: 50px !important;
  }
}

@media (max-width: 600px) {
  .modal-partner-error {
    margin-top: 0px !important;
  }
  // css page payment

  .title-card-pay {
    font-size: 22px !important;
    padding: 0px 10px !important;
    font-weight: bold;
    color: #1666b0;
    text-align: center;
  }

  .text-card-pay {
    font-size: 16px;
    color: #20305b;
    padding: 20px;
  }

  // logo denuncia-lexia

  .logo-denuncia-lexia {
    width: 500px;
    background-image: url("img/denuncia.online_logo_con_lexia.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: 0 auto;
  }
}

// codice css select number yousign

.react-tel-input {
  font-family: "Quicksand", sans-serif !important;
  font-size: 16px !important;
  position: relative;
  width: 100%;
}

.react-tel-input .form-control {
  height: 56px !important;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  width: 50px !important;
}

.react-tel-input .flag-dropdown .selected-flag {
  width: 50px !important;
}

.react-tel-input .form-control {
  padding-left: 64px !important;
  color: #20305b !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.react-tel-input .country-list .search-emoji {
  display: none !important;
}

.react-tel-input .selected-flag {
  padding: 0 0 0 14px !important;
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 2px #257dcf !important;
}

// testo input form yousign
.text-yousign-form {
  font-family: "Quicksand", sans-serif !important;
  font-weight: 500 !important;
  color: #20305b !important;
}

.icon-card-payment {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: url(../assets/img/icon-dashboard/payment-card.svg) no-repeat
    center center;
  background-size: contain;
  margin-right: 10px;
}

.input-stripe::placeholder {
  color: #20305b !important;
  font-weight: 400 !important;
}

@media (max-width: 600px) {
  .input-stripe-margin {
    margin-top: 10px !important;
  }
}

.icon-info-service-modal {
  display: inline-block;
  width: 25px;
  height: 25px;
  background: url(../assets/img/icon-dashboard/info.svg) no-repeat center center;
  background-size: contain;
  margin-left: 20px;
}

.p-PaymentElement {
  background-color: red !important;
}

.label.p-FieldLabel.Label.Label--empty {
  font-family: "Quicksand", sans-serif !important;
}

.title-dashboard-home-1 {
  font-weight: bold !important;
  text-transform: none !important;
  font-size: 22px !important;
  color: #fff !important;
}

.title-dashboard-home-2 {
  font-weight: bold !important;
  text-transform: none !important;
  font-size: 28px !important;
  color: #fff !important;
}

@media (max-width: 600px) {
  .title-dashboard-home-1 {
    font-size: 18px !important;
  }

  .title-dashboard-home-2 {
    font-size: 22px !important;
  }
}
