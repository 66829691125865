.TextHeaderPrimary {
  h4 {
    font-size: 2.25rem;
    color: #fff; // #333
    font-weight: 800;
  }
  @media (max-width: 600px) {
    .TextHeaderPrimary h4 {
      font-size: 1.8rem !important;
    }
  }
}
