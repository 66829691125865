.TextInfobox {
    &.info {
        background-color:#fff;
        .TextInfoboxBar {
            background-color: #383d41 !important;
        }
    }
    &.pending {
        background-color: #FFF5C3;
        .TextInfoboxBar {
            background-color: #FFD600;
        }
    }
    &.complete {
        background-color: #d4edda;
        .TextInfoboxBar {
            background-color: #155724;
        }
    }
    &.error {
        background-color: #FFDFDF;
        .TextInfoboxBar {
            background-color: #FF0F00;
        }
    }
    .TextInfoboxContent{
        padding-bottom: 0 !important;
        align-self: center;
        p{
            font-size: 0.8125rem;
        }
    }
}