.container-section {
  display: block;
  height: 200px;
  // background: linear-gradient(
  //   84deg,
  //   rgba(68, 59, 137, 1) 0%,
  //   rgba(75, 133, 180, 1) 87%
  // );
  background-color: #ffff;
  margin-top: 100px;
  color: #ffffff;
}

.container-section-talkAboutUs-aziende {
  display: block;
  height: 200px;
  background-color: #ffff;
  margin-top: 40px;
  color: #ffffff;
}

.text-section {
  text-align: center;
  font-size: 32px;
  font-weight: 600 !important;
  padding-top: 30px;
}

.laRepublica-logo {
  display: inline-block;
  width: 75%;
  height: 85%;
  background: url(../img/TalkAboutUs-IMG/logo-repubblica.svg) no-repeat center
    center;
  background-size: contain;
  margin-top: 5px;
}

.wired-logo {
  display: inline-block;
  width: 75%;
  height: 85%;
  background: url(../img/TalkAboutUs-IMG/wired.png) no-repeat center center;
  background-size: contain;
  margin-top: 5px;
}

.qnEconomia-logo {
  display: inline-block;
  width: 75%;
  height: 80%;
  background: url(../img/TalkAboutUs-IMG/QN.webp.png) no-repeat center center;
  background-size: contain;
  margin-top: 5px;
}

.ilGiornale-logo {
  display: inline-block;
  width: 100%;
  height: 100%;
  background: url(../img/TalkAboutUs-IMG/giornale.png) no-repeat center center;
  background-size: contain;
  margin-top: 5px;
}

.ilGiorno-logo {
  display: inline-block;
  width: 100%;
  height: 100%;
  background: url(../img/TalkAboutUs-IMG/giorno.png) no-repeat center center;
  background-size: contain;
  margin-top: 5px;
}

.leggo-logo {
  display: inline-block;
  width: 75%;
  height: 80%;
  background: url(../img/TalkAboutUs-IMG/leggo.png) no-repeat center center;
  background-size: contain;
  margin-top: 5px;
}

.mondoFinanza-logo {
  display: inline-block;
  width: 75%;
  height: 80%;
  background: url(../img/TalkAboutUs-IMG/mondofinanza.png) no-repeat center
    center;
  background-size: contain;
  margin-top: 5px;
}

.donnaModerna-logo {
  display: inline-block;
  width: 75%;
  height: 80%;
  background: url(../img/TalkAboutUs-IMG/DonnaModerna.png) no-repeat center
    center;
  background-size: contain;
  margin-top: 5px;
}

.carlino-logo {
  display: inline-block;
  width: 75%;
  height: 80%;
  background: url(../img/TalkAboutUs-IMG/logo-carlino.png) no-repeat center
    center;
  background-size: contain;
  margin-top: 5px;
}

.laNazione-logo {
  display: inline-block;
  width: 75%;
  height: 80%;
  background: url(../img/TalkAboutUs-IMG/logo-la-nazione.png) no-repeat center
    center;
  background-size: contain;
  margin-top: 12px;
}

.borsaItaliana-logo {
  display: inline-block;
  width: 75%;
  height: 80%;
  background: url(../img/TalkAboutUs-IMG/BorsaItaliana-logo.svg) no-repeat
    center center;
  background-size: contain;
  margin-top: 12px;
}

@media (max-width: 900px) {
  .container-section {
    margin-top: 40px;
  }
}

@media (max-width: 600px) {
  .container-section {
    margin-top: 40px;
  }

  .text-section {
    padding-top: 0px;
  }

  .wired-logo {
    width: 100px;
    height: 80px;
  }

  .wired-logo {
    width: 100px;
    height: 80px;
  }

  .qnEconomia-logo {
    width: 100px;
    height: 80px;
  }

  .ilGiornale-logo {
    width: 100px;
    height: 80px;
  }

  .ilGiorno-logo {
    width: 100px;
    height: 80px;
  }

  .leggo-logo {
    width: 100px;
    height: 80px;
  }

  .mondoFinanza-logo {
    width: 100px;
    height: 80px;
  }

  .donnaModerna-logo {
    width: 100px;
    height: 80px;
  }

  .carlino-logo {
    width: 100px;
    height: 80px;
  }

  .laNazione-logo {
    width: 100px;
    height: 80px;
  }

  .borsaItaliana-logo {
    width: 100px;
    height: 80px;
  }
}
