.container-sectionFour-aziende {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 50px;
  margin-bottom: 80px;
}
.background-purple-light {
  // background-color: #eaf2f7;
  background-color: rgba(71, 60, 144, 0.108);
  height: 500px;
  border-radius: 450px 450px 0px 0px;
}

.title-card-sectionFour-aziende {
  font-size: 48px !important;
  font-weight: 600 !important;
  color: #fff !important;
}

@media (max-width: 1024px) {
  .title-card-sectionFour-aziende {
    font-size: 42px !important;
    font-weight: 600 !important;
  }
}

@media (max-width: 768px) {
  .title-card-sectionFour-aziende {
    font-size: 36px !important;
    font-weight: 600 !important;
  }
}

.subtitle-card-sectionFour-aziende {
  color: #ffffff;
  font-size: 18px;
}

.text-info-sectionFour-aziende {
  color: #ffffff !important;
  font-size: 16px;
}

.overlay-sectionFour-aziende {
  background: linear-gradient(
    84deg,
    rgba(32, 48, 91, 1) 0%,
    rgba(50, 54, 120, 1) 20%,
    rgba(71, 60, 144, 1) 100%
  ) !important;
  padding: 20px;
  width: 80%;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  border-radius: 50px;
}

.btn-sectionFour-aziende {
  background-color: #ffffff;
  color: rgba(71, 60, 144, 1) !important;
  width: 200px !important;
  height: 50px;
  padding: 12px;
  border-radius: 0px 20px 20px 0px;
  font-weight: bold;
  width: 200px !important;
  font-size: 18px !important;
}

.btn-sectionFour-aziende:hover {
  background-color: #ffffff;
  color: rgba(71, 60, 144, 1) !important;
}

@media (max-width: 900px) {
  .container-sectionFour-aziende {
    margin-bottom: 0px !important;
  }
  .title-card-sectionFour-aziende {
    margin-top: 15px !important;
  }
  .subtitle-card-sectionFour-aziende {
    color: #ffffff;
    font-size: 16px;
  }

  .text-info-sectionFour-aziende {
    color: #ffffff !important;
    font-size: 14px;
  }
  .background-purple-light {
    background-color: transparent;
    height: 350px !important;
    border-radius: 50px 50px 50px 50px;
  }
}

@media (max-width: 650px) {
  .background-purple-light {
    background-color: transparent;
    height: 350px;
    border-radius: 50px 50px 50px 50px;
  }

  .overlay-sectionFour-aziende {
    background: linear-gradient(
      84deg,
      rgba(32, 48, 91, 1) 0%,
      rgba(50, 54, 120, 1) 20%,
      rgba(71, 60, 144, 1) 100%
    ) !important;
    padding: 20px;
    width: 80%;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    border-radius: 50px;
  }
}

@media (max-width: 400px) {
  .overlay-sectionFour-aziende {
    background: linear-gradient(
      84deg,
      rgba(32, 48, 91, 1) 0%,
      rgba(50, 54, 120, 1) 20%,
      rgba(71, 60, 144, 1) 100%
    ) !important;
    padding: 20px;
    width: 80%;
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    border-radius: 50px;
  }
}

@media (max-width: 380px) {
  .container-sectionFour-aziende {
    margin-bottom: 340px !important;
  }
}
