.ButtonPrimary {
  * {
    padding: 15px;
    text-transform: none;
  }
  .button-custom {
    background-color: #58979d;
    color: #fff;
    border-radius: 10px;
    transition: 0.4s ease;
  }
  .button-custom:hover {
    background-color: #58979d;
    filter: brightness(0.9);
  }
}

.ButtonPrimaryConsulenza {
  * {
    padding: 0px;
    text-transform: none;
  }
  .button-custom {
    background-color: #58979d;
    color: #fff;
    border-radius: 10px;
    transition: 0.4s ease;
  }
  .button-custom:hover {
    background-color: #58979d;
    filter: brightness(0.9);
  }
}
