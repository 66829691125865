.container-section-4 {
  position: relative;
  margin-bottom: 220px;
  display: flex;
  margin-top: 80px !important;
}

.container-animation-2 {
  width: 500px;
  height: 500px;
  position: absolute;
  top: 100;
  left: 50px;
}

.img-lawyer-container {
  position: relative;
  width: 400px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.title-sectionThree-denuncia {
  font-size: 48px !important;
  font-weight: 600 !important;
}

.subtitle-sectionThree-denuncia {
  font-size: 30px !important;
}

@media (max-width: 1024px) {
  .title-sectionThree-denuncia {
    font-size: 42px !important;
    font-weight: 600 !important;
  }
  .subtitle-sectionThree-denuncia {
    font-size: 26px !important;
  }
}

@media (max-width: 768px) {
  .title-sectionThree-denuncia {
    font-size: 36px !important;
    font-weight: 600 !important;
  }
  .subtitle-sectionThree-denuncia {
    font-size: 22px !important;
  }
}

.icon1 {
  background: url(../img/pc.png) no-repeat;
  background-size: contain;
  height: 60px;
  width: 60px;
}

.icon1-consulenza {
  background: url(../img/pc-consulenza.png) no-repeat;
  background-size: contain;
  height: 60px;
  width: 60px;
}

.icon2 {
  background: url(../img/finger-snap.png) no-repeat;
  background-size: contain;
  height: 60px;
  width: 60px;
}

.icon2-consulenza {
  background: url(../img/finger-snap-consulenza.png) no-repeat;
  background-size: contain;
  height: 60px;
  width: 60px;
}

.icon3 {
  background: url(../img/fast.png) no-repeat;
  background-size: contain;
  height: 60px;
  width: 60px;
}

.icon3-consulenza {
  background: url(../img/fast-consulenza.png) no-repeat;
  background-size: contain;
  height: 60px;
  width: 60px;
}

.icon4 {
  background: url(../img/security.png) no-repeat;
  background-size: contain;
  height: 60px;
  width: 60px;
}

.icon4-consulenza {
  background: url(../img/security-consulenza.png) no-repeat;
  background-size: contain;
  height: 60px;
  width: 60px;
}

.ref {
  background: url(../../assets/img/ReferenceLexia.png);
  width: 120px !important;
  height: 60px !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 30px;
}
.btn-section-4 {
  background: linear-gradient(
    84deg,
    rgba(68, 59, 137, 1) 0%,
    rgba(75, 133, 180, 1) 87%
  );
  width: 200px;
  height: 50px;
  padding: 11px;
  font-weight: bold;
  border-radius: 0px 20px 20px 0px;
  color: #ffffff;
  font-size: 18px !important;
  display: flex !important;
  justify-content: center !important;
}
.btn-section-4:hover {
  color: #ffffff;
}

.btn-section-consulenza {
  margin-top: 20px;
  width: 200px !important;
  padding: 10px !important;
  font-weight: bold !important;
  background: linear-gradient(
    84deg,
    rgba(75, 133, 180, 1) 0%,
    rgb(79, 164, 168) 97%
  ) !important;
  color: #ffffff !important;
  border-radius: 0px 20px 20px 0px !important;
  font-size: 18px !important;
  display: flex !important;
  justify-content: center !important;
}
.btn-section-consulenza:hover {
  color: #ffffff !important;
}
.link-section-4 {
  color: #20305b;
}
.link-section-4:hover {
  color: #20305b;
}
.text-paragraf {
  font-size: 16px;
}
.container-animation-2-smart {
  display: none;
}

.img-lawyer-container-smart {
  display: none !important;
}

@media (min-width: 992px) and (max-width: 1080px) {
  .col-custom {
    width: 90% !important;
  }
}
@media (max-width: 1080px) {
  .container-section-4 {
    margin-top: 0px !important;
    display: inline-block;
    margin-bottom: 180px !important;
  }
  .container-animation-2 {
    display: none;
  }
  .img-lawyer-container {
    display: none !important;
  }
  .container-animation-2-smart {
    width: 300px;
    height: 300px;
    position: absolute;
    top: 500;
    left: 20px;
    display: block;
  }

  .img-lawyer-container-smart {
    position: relative;
    width: 400px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    display: block !important;
  }

  .box2-section-4 {
    padding: 30px;
    margin-top: 20px;
  }
  .box3-section-4 {
    margin-top: 350px;
  }
  .subtitle-section-4 {
    font-size: 18px;
  }
  .text-paragraf {
    font-size: 14px;
  }
  .margin-bottom-5 {
    margin-bottom: 20px !important;
  }
}

@media (max-width: 900px) {
  .container-section-4 {
    margin-bottom: 120px !important;
  }
  .img-lawyer-container-smart {
    width: 350px;
    height: 350px;
    margin-left: 0px !important;
  }
}
@media (max-width: 500px) {
  .container-section-4 {
    margin-bottom: 120px !important;
  }
}
@media (max-width: 1200px) {
  .container-animation-2 {
    width: 500px;
    height: 500px;
    position: absolute;
    top: 100;
    left: 0px;
  }
}

@media (max-width: 400px) {
  .box2-section-4 {
    padding: 30px;
    margin-top: 20px;
  }
  .container-section-4 {
    margin-bottom: 200px !important;
  }
}

.img-lawyer {
  position: absolute;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.img-lawyer-1 {
  background-image: url("../img/lawyer-1.png");
  z-index: 2;
  left: -30%;
}

.img-lawyer-2 {
  background-image: url("../img/lawyer-2.png");
  z-index: 1;
  left: 20%;
}

.img-lawyer-container {
  position: relative;
  width: 400px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

@media (max-width: 1080px) {
  .img-lawyer-container-smart {
    margin-left: 70px !important;
  }
}

@media (max-width: 700px) {
  .img-lawyer-container-smart {
    margin-left: 0px !important;
  }
}

@media (max-width: 500px) {
  .img-lawyer-container-smart {
    width: 250px;
    height: 250px;
    margin-left: 50px !important;
  }

  .img-lawyer {
    position: absolute;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .img-lawyer-1 {
    background-image: url("../img/lawyer-1.png");
    z-index: 2;
    left: -50%;
  }

  .img-lawyer-2 {
    background-image: url("../img/lawyer-2.png");
    z-index: 1;
    left: 0%;
  }
}
