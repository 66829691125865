.ReportDataCard {
  color: rgb(0, 0, 0) !important;
  display: flex;
  flex-direction: row;
  .ReportDataCardIcon div {
    background-color: #ececec;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    width: 25px;
    padding: 18px 18px;
    height: 100%;
  }
  .ReportDataCardIconCalendly div {
    background-color: #ececec;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    width: 25px;
    padding: 18px 18px;
    height: 100%;
  }
  button {
    box-shadow: none !important;
  }
  .CardStatus {
    color: #20305b;
    text-transform: uppercase;
    font-size: 0.625rem;
    font-weight: 600;
    .CardStatusCircle {
      width: 12px;
      height: 12px;
    }
  }
  .CardDate {
    color: #20305b;
    font-weight: 300;
    margin-top: 5px;
  }
  .CardType {
    color: #1666b0;
    font-weight: 600 !important;
    font-size: 0.75rem;
    letter-spacing: 0.05rem;
    font-style: italic;
    text-transform: capitalize;
  }

  .button-card {
    background-color: #58979d;
    color: #fff;
    transition: 0.4s ease;
  }

  .button-card:hover {
    background-color: #58979d;
    filter: brightness(0.9);
  }
}
