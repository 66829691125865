.container-section-3 {
  width: 85% !important;
  background: linear-gradient(
    84deg,
    rgba(68, 59, 137, 1) 0%,
    rgba(75, 133, 180, 1) 87%
  ) !important;
  border-radius: 0px 450px 450px 0px !important;
  position: relative !important;
  margin-top: 100px !important;
  margin-bottom: 80px !important;
}

.box2-section-3 {
  padding-top: 60px !important;
  margin-left: 100px !important;
}

.title-sectionTwo-denuncia {
  font-size: 48px !important;
  font-weight: 600 !important;
}

.subtitle-sectionTwo-denuncia {
  font-size: 30px !important;
}

@media (max-width: 1024px) {
  .title-sectionTwo-denuncia {
    font-size: 42px !important;
    font-weight: 600 !important;
  }
  .subtitle-sectionTwo-denuncia {
    font-size: 26px !important;
  }
}

@media (max-width: 768px) {
  .title-sectionTwo-denuncia {
    font-size: 36px !important;
    font-weight: 600 !important;
  }
  .subtitle-sectionTwo-denuncia {
    font-size: 22px !important;
  }
}

.box-text {
  margin-top: 0px !important;
  margin-left: 100px !important;
}
.bubbles-container {
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  justify-content: flex-start !important;
  margin-top: 50px !important;
}
.bubble {
  // 80
  width: 50px !important;
  height: 50px !important;
  border-radius: 50% !important;
  background-color: #ffffff !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-bottom: 60px !important;
  position: relative !important;
}
.bubble::before {
  content: "" !important;
  position: absolute !important;
  top: -35% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  height: 80px !important;
  color: #ffffff !important;
}

.bubbles-container .first-bubble::before {
  display: none !important;
}
.bubbles-container .second-bubble::before {
  content: "" !important;
  position: absolute !important;
  top: -60% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  height: 87px !important;
  color: #ffffff !important;
  border: 1px solid #ffffff !important;
  border-style: dashed !important;
}
.bubbles-container .three-bubble::before {
  content: "" !important;
  position: absolute !important;
  top: -60% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  height: 70px !important;
  color: #ffffff !important;
  border: 1px solid #ffffff !important;
  border-style: dashed !important;
}

.bubble-text {
  font-size: 32px !important;
  color: #1666b0 !important;
  font-weight: 500 !important;
}

.text-title-bubble {
  font-size: 24px !important;
  color: #ffffff !important;
  font-weight: 600 !important;
}
.subtitle-title-bubble {
  font-size: 18px !important;
  color: #ffffff !important;
  font-weight: 400 !important;
}
.button-section-3 {
  background-color: #ffffff !important;
  color: #1666b0 !important;
  width: 200px !important;
  height: 50px !important;
  font-weight: bold !important;
  padding: 11px !important;
  border-radius: 0px 20px 20px 0px !important;
  font-size: 18px !important;
  display: flex !important;
  justify-content: center !important;
}
.button-section-3:hover {
  background-color: #ffffff !important;
  color: #1666b0 !important;
}

.button-section-3-consulenza {
  background-color: #ffffff !important;
  color: #4b85b4 !important;
  width: 200px !important;
  height: 50px !important;
  font-weight: bold !important;
  padding: 11px !important;
  border-radius: 0px 20px 20px 0px !important;
  font-size: 18px !important;
  display: flex !important;
  justify-content: center !important;
}
.button-section-3-consulenza:hover {
  background-color: #ffffff !important;
  color: #4b85b4 !important;
}

.button-section-3-primary {
  background-color: #58979d !important;
  color: #fff !important;
  width: 140px !important;
  height: 40px !important;
  border-radius: 0px 20px 20px 0px !important;
}
.button-section-3-primary:hover {
  background-color: #58979d !important;
  color: #fff !important;
}

.box-text {
  margin-left: 50px !important;
  width: 70% !important;
  margin-top: -5px !important;
}

.box-button {
  display: flex !important;
  // justify-content: start !important;
  margin-top: 30px !important;
  // margin-left: 100px !important;
}

.box-button-inizia {
  margin-right: 150px !important;
}

.linkFaq {
  font-weight: 600;
  padding-top: 10px !important;
  font-size: 16px;
  color: #fff;
}

@media (max-width: 400px) {
  .img-section-3-denuncia {
    display: none !important;
  }
  .linkFaq {
    margin-left: 20px !important;
  }
}
@media (max-width: 1335px) {
  .bubbles-container .three-bubble::before {
    content: "" !important;
    position: absolute !important;
    top: -80% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: 80px !important;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-style: dashed !important;
  }
}
@media (max-width: 1215px) {
  .bubbles-container .three-bubble::before {
    content: "" !important;
    position: absolute !important;
    top: -80% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: 80px !important;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-style: dashed !important;
  }
}
@media (max-width: 1180px) {
  .container-section-3 {
    width: 100% !important;
    margin-top: 100px !important;
    background: linear-gradient(
      84deg,
      rgba(68, 59, 137, 1) 0%,
      rgba(75, 133, 180, 1) 87%
    ) !important;
    position: relative !important;
    border-radius: 0px !important;
    height: 650px !important;
    margin-bottom: 0px !important;
  }

  .img-section-3-denuncia {
    display: none;
  }

  .box2-section-3 {
    padding-top: 40px !important;
    margin-left: 30px !important;
    width: 80% !important;
  }

  .bubbles-container {
    margin-top: 30px !important;
  }

  .title-sectionTwo-denuncia {
    margin-top: 20px !important;
    margin-left: 20px !important;
  }

  .bubble {
    border-radius: 50% !important;
    background-color: #ffffff !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-bottom: 40px !important;
    position: relative !important;
    margin-left: 20px !important;
  }

  .bubbles-container .second-bubble::before {
    content: "" !important;
    position: absolute !important;
    top: -45% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: 45px !important;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-style: dashed !important;
  }

  .bubbles-container .three-bubble::before {
    content: "" !important;
    position: absolute !important;
    top: -40% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: 40px !important;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-style: dashed !important;
  }

  .last-bubble::before {
    height: 215px !important;
    overflow: hidden !important;
  }

  .subtitle-sectionTwo-denuncia {
    margin-left: 20px !important;
  }

  .bubble-text {
    font-size: 32px !important;
    color: #1666b0 !important;
    font-weight: 500 !important;
  }

  .text-title-bubble {
    font-size: 16px !important;
  }

  .subtitle-title-bubble {
    font-size: 14px !important;
  }

  .box-text {
    margin-left: 20px !important;
    width: 70% !important;
  }

  .box-button-inizia {
    margin-right: 20px !important;
  }

  .box-button {
    display: flex !important;
    // justify-content: center !important;
    margin-top: 20px !important;
  }
}
@media (max-width: 780px) {
  .container-section-3 {
    height: 100% !important;
  }
}
@media (max-width: 984px) {
  .bubbles-container .three-bubble::before {
    content: "" !important;
    position: absolute !important;
    top: -53% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: 50px !important;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-style: dashed !important;
  }
}
@media (max-width: 800px) {
  .box-button {
    text-align: center;
  }
  .box-button a {
    display: inline-block;
  }
}
@media (max-width: 670px) {
  .box2-section-3 {
    padding-top: 40px !important;
    margin-left: 20px !important;
    width: 80% !important;
  }
  .bubbles-container {
    margin-top: 30px !important;
  }
  .title-section-3 {
    margin-top: 20px !important;
    margin-left: 20px !important;
  }
  .bubble {
    border-radius: 50% !important;
    background-color: #ffffff !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin-bottom: 40px !important;
    position: relative !important;
    margin-left: 20px !important;
  }
  .bubbles-container .second-bubble::before {
    content: "" !important;
    position: absolute !important;
    top: -51% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: 47px !important;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-style: dashed !important;
  }
  .bubbles-container .three-bubble::before {
    content: "" !important;
    position: absolute !important;
    top: -53% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: 50px !important;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-style: dashed !important;
  }
  .last-bubble::before {
    height: 215px !important ;
    overflow: hidden !important ;
  }
  .subtitle-sectionTwo-denuncia {
    margin-left: 20px !important ;
  }
  .bubble-text {
    font-size: 32px !important;
    color: #1666b0 !important;
    font-weight: 500 !important;
  }

  .text-title-bubble {
    font-size: 16px !important;
  }
  .subtitle-title-bubble {
    font-size: 14px !important;
  }
  .box-text {
    margin-left: 20px !important;
    width: 70% !important;
  }
  .box-button-inizia {
    margin-right: 20px !important;
  }
  .box-button {
    display: flex !important;
    // justify-content: center !important;
    margin-top: 20px !important;
  }
}
@media (max-width: 668px) {
  .bubbles-container .three-bubble::before {
    content: "" !important;
    position: absolute !important;
    top: -44% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: 69px !important;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-style: dashed !important;
  }
}
@media (max-width: 496px) {
  .bubbles-container .three-bubble::before {
    content: "" !important;
    position: absolute !important;
    top: -70% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: 63px !important;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-style: dashed !important;
  }
}
@media (max-width: 472px) {
  .bubbles-container .second-bubble::before {
    content: "" !important;
    position: absolute !important;
    top: -55% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: 70px !important;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-style: dashed !important;
  }
}
@media (max-width: 446px) {
  .bubbles-container .three-bubble::before {
    content: "" !important;
    position: absolute !important;
    top: -70% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: 65px !important;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-style: dashed !important;
  }
}
@media (max-width: 415px) {
  .bubbles-container .three-bubble::before {
    content: "" !important;
    position: absolute !important;
    top: -85% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: 90px !important;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-style: dashed !important;
  }
}
@media (max-width: 380px) {
  .bubbles-container .three-bubble::before {
    content: "" !important;
    position: absolute !important;
    top: -105% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: 100px !important;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-style: dashed !important;
  }
}
@media (max-width: 351px) {
  .bubbles-container .three-bubble::before {
    content: "" !important;
    position: absolute !important;
    top: -105% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    height: 100px !important;
    color: #ffffff !important;
    border: 1px solid #ffffff !important;
    border-style: dashed !important;
  }
}
@media (max-width: 600px) {
  .box-button {
    margin-bottom: 20px !important;
  }
  .link-faq-margin {
    margin-bottom: 30px !important;
    margin-left: 90px !important;
  }
  .box-button-inizia {
    display: flex !important;
    margin-left: 90px !important;
  }
}
@media (max-width: 900px) {
  .container-section-3 {
    margin-top: 20px !important;
  }

  .box-button {
    margin-left: 0px !important;
  }
}
@media (max-width: 410px) {
  .link-faq-margin {
    margin-left: 90px !important;
  }
}

// grafica

.img-section-3-denuncia {
  position: absolute;
  bottom: -6%;
  left: 70%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 400px;
  background: url(../img/how_it_works.svg) no-repeat;
  background-size: cover;
  z-index: 10;
  transform: rotateY(180deg) !important;
  align-self: flex-end;
  margin-bottom: 0;
}

@media (max-width: 1400px) {
  .img-section-3-denuncia {
    bottom: -5%;
  }
}
