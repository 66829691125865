.container-section-8 {
  height: 100%;
  clear: both;
  z-index: 1;
}
.bg-footer {
  background-color: #20305b;
}

.text-payment {
  font-size: 16px !important;
  margin-left: 20px !important;
  margin-top: 4px !important;
  color: #fff !important;
}

.logo-footer {
  background: url(../img/logo-01.png) no-repeat;
  width: 100px;
  height: 100px;
  transform: rotate(270deg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.link-footer {
  color: #ffffff;
}
.link-footer:hover {
  color: #ffffff;
}
.box-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 48px;
  padding-bottom: 64px;
}

.box-footer-offerta {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 48px;
  padding-bottom: 94px;
}

.img-footer-badge {
  background: url(../../assets/img/img-footer/badge.png);
  width: 60px !important;
  height: 60px;
  background-size: contain;
  background-repeat: no-repeat;
}

.img-footer-visa {
  background: url(../../assets/img/img-footer/Visa.svg);
  width: 40px !important;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
}

.img-footer-mastercard {
  background: url(../../assets/img/img-footer/Mcard.svg);
  width: 40px !important;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
}

.img-footer-amex {
  background: url(../../assets/img/img-footer/Amex.svg);
  width: 40px !important;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
}

.img-footer-paypal {
  background: url(../../assets/img/img-footer/PP.svg);
  width: 40px !important;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
}

.img-footer-stripe {
  background: url(../../assets/img/img-footer/Stripe.svg);
  width: 40px !important;
  height: 40px;
  background-size: contain;
  background-repeat: no-repeat;
}

.container-logo-payment {
  margin-left: -80px;
}

.container-payment {
  margin-top: 20px;
  margin-bottom: 20px;
}
@media (max-width: 1350px) {
  .container-logo-payment {
    margin-left: -40px;
  }
}
@media (max-width: 950px) {
  .text-footer {
    font-size: 12px;
    text-align: center;
    padding: 10px;
    margin: 0;
  }
  .text-info-footer {
    font-size: 10px;
  }
  .box-footer {
    padding-top: 18px;
    padding-bottom: 18px;
  }
  .box-footer-offerta {
    padding-top: 18px;
    padding-bottom: 100px;
  }
}

@media (max-width: 1450px) {
  .container-logo-payment {
    margin-left: -60px;
  }
}

@media (max-width: 1350px) {
  .container-logo-payment {
    margin-left: 0px;
  }
}

@media (max-width: 400px) {
  .text-payment {
    margin-left: 10px !important;
  }
}
