.container-section-5 {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 50px;
  margin-bottom: 80px;
}
.background-gray {
  background-color: #eaf2f7;
  height: 580px;
  border-radius: 450px 450px 0px 0px;
}

.background-under-consulenza {
  background-color: #95e0e44f;
  height: 580px;
  border-radius: 450px 450px 0px 0px;
}

.title-sectionFour-denuncia {
  font-size: 48px !important;
  font-weight: 600 !important;
  color: #ffffff;
}

.subtitle-card {
  color: #ffffff;
  font-size: 18px;
}

@media (max-width: 1024px) {
  .title-sectionFour-denuncia {
    font-size: 42px !important;
    font-weight: 600 !important;
  }
}

@media (max-width: 768px) {
  .title-sectionFour-denuncia {
    font-size: 36px !important;
    font-weight: 600 !important;
  }
}

.price-card {
  margin: 0px;
  padding: 0px;
  font-size: 54px;
  color: #ffffff;
  font-weight: bold;
}
.number-price-card {
  font-size: 84px;
  color: #ffffff;
  font-weight: bold;
}
.number2-price-card {
  font-size: 54px;
  color: #ffffff;
  font-weight: bold;
  vertical-align: 25%;
}
.subtitle2-card {
  color: #ffffff;
  font-size: 18px;
  padding-left: 90px;
  padding-right: 90px;
}
.text-info {
  color: #ffffff !important;
  font-size: 16px;
}
.box-card {
  padding: 20px 40px 20px 40px;
}
.overlay-div {
  background: linear-gradient(
    84deg,
    rgba(68, 59, 137, 1) 0%,
    rgba(75, 134, 180, 1) 100%
  );
  height: 600px;
  padding: 20px;
  width: 80%;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  border-radius: 50px;
}

.overlay {
  background: linear-gradient(
    84deg,
    rgba(68, 59, 137, 1) 0%,
    rgba(75, 134, 180, 1) 100%
  );
  padding: 20px;
  width: 80%;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  border-radius: 50px;
}

.overlay-consulenza {
  background: linear-gradient(
    84deg,
    rgba(75, 133, 180, 1) 0%,
    rgb(79, 164, 168) 97%
  ) !important;
  padding: 20px;
  width: 80%;
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  border-radius: 50px;
}

.btn-section-5 {
  background-color: #ffffff;
  color: #1666b0;
  width: 200px !important;
  height: 50px;
  padding: 12px;
  border-radius: 0px 20px 20px 0px;
  font-weight: bold;
  width: 200px !important;
  font-size: 18px !important;
}

.btn-section-5:hover {
  background-color: #ffffff;
  color: #1666b0;
}

.btn-section-5-consulenza {
  background-color: #ffffff;
  color: #4b85b4;
  width: 200px !important;
  height: 50px;
  padding: 12px;
  border-radius: 0px 20px 20px 0px;
  font-weight: bold;
  width: 200px !important;
  font-size: 18px !important;
}

.btn-section-5-consulenza:hover {
  background-color: #ffffff;
  color: #4b85b4;
}

@media (max-width: 1200px) {
  .overlay-div {
    height: 680px;
    background: linear-gradient(
      84deg,
      rgba(68, 59, 137, 1) 0%,
      rgba(75, 134, 180, 1) 100%
    );
    padding: 20px;
    width: 80%;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    border-radius: 50px;
  }
}
@media (max-width: 900px) {
  .container-section-5 {
    margin-bottom: 320px !important;
  }
  .title-card {
    font-size: 34px;
    color: #ffffff;
    margin-top: 15px !important;
  }
  .subtitle-card {
    color: #ffffff;
    font-size: 16px;
  }
  .price-card {
    font-size: 24px;
  }
  .number-price-card {
    font-size: 64px;
  }
  .number2-price-card {
    font-size: 44px;
    color: #ffffff;
    font-weight: bold;
    vertical-align: 25%;
  }
  .overlay-div {
    height: 10%;
    background: linear-gradient(
      84deg,
      rgba(68, 59, 137, 1) 0%,
      rgba(75, 134, 180, 1) 100%
    );
    padding: 20px;
    width: 80%;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    border-radius: 50px;
  }
  .text-info {
    color: #ffffff !important;
    font-size: 14px;
  }
  .background-gray {
    background-color: transparent;
    height: 530px;
    border-radius: 50px 50px 50px 50px;
  }
}
@media (max-width: 650px) {
  .background-gray {
    background-color: transparent;
    height: 580px;
    width: 100%;
  }

  .overlay-div {
    height: 720px;
    background: linear-gradient(
      84deg,
      rgba(68, 59, 137, 1) 0%,
      rgba(75, 134, 180, 1) 100%
    );
    padding: 20px;
    width: 80%;
    position: absolute;
    top: 25%;
    margin-left: 12px;
    left: 48%;
    transform: translate(-50%, -50%);
    z-index: 1;
    border-radius: 50px;
  }
  .background-gray {
    margin-left: 0px;
    margin-right: 3px;
  }

  .overlay {
    background: linear-gradient(
      84deg,
      rgba(68, 59, 137, 1) 0%,
      rgba(75, 134, 180, 1) 100%
    );
    padding: 20px;
    width: 80%;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    border-radius: 50px;
  }
}
@media (max-width: 400px) {
  .overlay {
    background: linear-gradient(
      84deg,
      rgba(68, 59, 137, 1) 0%,
      rgba(75, 134, 180, 1) 100%
    );
    padding: 20px;
    width: 80%;
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    border-radius: 50px;
  }
}
@media (max-width: 380px) {
  .container-section-5 {
    margin-bottom: 340px !important;
  }
}
