.CardBlog {
  .button-card {
    background-color: #1666b0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    color: #fff;
    padding: "5px";
  }

  .button-card:hover {
    background-color: #20305b !important;
  }
}
