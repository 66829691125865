.ButtonSecondary {
  * {
    padding: 15px;
    text-transform: none;
  }
  .button-custom-secondary {
    background-color: transparent !important;
    color: #fff !important;
    border: 1px solid #fff;
    border-radius: 10px;
    transition: 0.4s ease;
    padding: 15px;
  }
  .button-custom-secondary:hover {
    background-color: transparent !important;
    filter: brightness(0.7);
  }
}

.ButtonSecondaryConsulenza {
  * {
    padding: 0px;
    text-transform: none;
  }
  .button-custom-secondary {
    background-color: transparent !important;
    color: #fff !important;
    border: 1px solid #fff;
    border-radius: 10px;
    transition: 0.4s ease;
  }
  .button-custom-secondary:hover {
    background-color: transparent !important;
    filter: brightness(0.7);
  }
}
