.container-section-6 {
  width: 80%;
  margin-bottom: 100px;
}
.accordion-item {
  border: none;
}
.accordion-button {
  border-bottom: 1px solid rgb(219, 218, 218);
  color: #20305b;
  font-weight: 600;
}
.accordion-header {
  border: 0;
}
.accordion-button:not(.collapsed) {
  background-color: #ffffff;
  color: #20305b;
  font-weight: 500;
}
.accordion-button:focus {
  border: none;
  box-shadow: none;
}
.accordion-button::after {
  background-image: url(../img/plus-circle.svg);
}
.accordion-button:not(.collapsed)::after {
  background-image: url(../img/dash-circle.svg);
}
.link-faq {
  color: #20305b;
}
.link-faq:hover {
  color: #20305b;
}

.title-sectionFaq-denuncia {
  font-size: 48px !important;
  font-weight: 600 !important;
}

.subtitle-sectionFaq-denuncia {
  font-size: 30px !important;
}

@media (max-width: 1024px) {
  .title-sectionFaq-denuncia {
    font-size: 42px !important;
    font-weight: 600 !important;
  }
  .subtitle-sectionFaq-denuncia {
    font-size: 26px !important;
  }
}

@media (max-width: 768px) {
  .title-sectionFaq-denuncia {
    font-size: 36px !important;
    font-weight: 600 !important;
  }
  .subtitle-sectionFaq-denuncia {
    font-size: 22px !important;
  }
}

@media (max-width: 900px) {
  .container-section-6 {
    margin-top: -360px !important;
    margin-bottom: 350px !important;
  }
}

@media (max-width: 600px) {
  .container-section-6 {
    margin-top: -430px !important;
  }
}

@media (max-width: 500px) {
  .container-section-6 {
    margin-top: -430px !important;
  }
}

@media (max-width: 400px) {
  .container-section-6 {
    margin-top: -430px !important;
  }
}
