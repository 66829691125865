.cover-image-consulenza-home {
  width: 100% !important;
  height: 100% !important;
  max-width: 450px !important;
}

.title-sectionConsulenza-denuncia {
  font-size: 48px !important;
  font-weight: 600 !important;
}

@media (max-width: 1024px) {
  .title-sectionConsulenza-denuncia {
    font-size: 42px !important;
    font-weight: 600 !important;
  }
}

@media (max-width: 768px) {
  .title-sectionConsulenza-denuncia {
    font-size: 36px !important;
    font-weight: 600 !important;
  }
}

.container-consulenza-minorSection {
  height: 420px !important;
  margin-top: 120px !important;
}

.container-text-consulenza {
  margin-left: 100px !important;
}

#lottie-animation-consulenza-home {
  position: relative !important;
  top: -70% !important;
  left: 25% !important;
  transform: translateY(50%) !important;
}

@media (max-width: 1800px) {
  #lottie-animation-consulenza-home {
    position: relative !important;
    top: -75% !important;
    left: 40% !important;
    transform: translateY(50%) !important;
  }
}

@media (max-width: 1300px) {
  #lottie-animation-consulenza-home {
    position: relative !important;
    top: -70% !important;
    left: 30% !important;
    transform: translateY(50%) !important;
  }
}

@media (max-width: 1200px) {
  #lottie-animation-consulenza-home {
    position: relative !important;
    top: -65% !important;
    left: 20% !important;
    transform: translateY(50%) !important;
  }
}

@media (max-width: 1100px) {
  .cover-image-consulenza-home {
    width: 100% !important;
    height: 100% !important;
    max-width: 400px !important;
  }

  #lottie-animation-consulenza-home {
    position: relative !important;
    top: -50% !important;
    left: 20% !important;
    transform: translateY(50%) !important;
  }
}

@media (max-width: 1000px) {
  #lottie-animation-consulenza-home {
    position: relative !important;
    top: -50% !important;
    left: 5% !important;
    transform: translateY(50%) !important;
  }
}

@media (max-width: 900px) {
  .container-consulenza-minorSection {
    height: 350px !important;
    margin-top: 120px !important;
    margin-bottom: -40px !important;
  }

  .cover-image-consulenza-home {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
    max-width: 350px !important;
  }

  #lottie-animation-consulenza-home {
    position: relative !important;
    left: -5% !important;
    top: -350px !important;
    transform: translate(0, 0) !important;
  }

  .container-text-consulenza {
    margin-top: -350px !important;
    margin-left: 30px !important;
  }
}

@media (max-width: 810px) {
  .container-consulenza-minorSection {
    height: 350px !important;
    margin-top: 120px !important;
    margin-bottom: 0px !important;
  }
}

@media (max-width: 600px) {
  #lottie-animation-consulenza-home {
    top: -420px !important;
  }
  .container-text-consulenza {
    margin-top: -450px !important;
  }
}

@media (max-width: 550px) {
  .container-consulenza-minorSection {
    height: 400px !important;
    margin-top: 120px !important;
    margin-bottom: 0px !important;
  }
}

@media (max-width: 450px) {
  .container-consulenza-minorSection {
    height: 400px !important;
    margin-top: 120px !important;
    margin-bottom: 50px !important;
  }
}

@media (max-width: 400px) {
  .container-consulenza-minorSection {
    height: 300px !important;
    margin-top: 120px !important;
    margin-bottom: 50px !important;
  }

  .container-text-consulenza {
    margin-top: -420px !important;
  }
}
