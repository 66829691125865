.Blog {
  .title {
    color: #1666b0;
    font-size: 42px;
    font-weight: 500;
    text-align: center;
  }
  .textDescription {
    font-size: 18px;
    font-family: Quicksand, sans-serif !important;
  }
  .textDescription span {
    font-family: Quicksand, sans-serif !important;
  }
}
