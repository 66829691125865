.container-logo-sectionDas-aziende {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  max-width: 100%;
}

.title-sectionDas-aziende {
  font-size: 48px !important;
  font-weight: 600 !important;
}

.subtitle-sectionDas-aziende {
  font-size: 30px !important;
  font-weight: 600 !important;
}

@media (max-width: 1024px) {
  .title-sectionDas-aziende {
    font-size: 42px !important;
    font-weight: 600 !important;
  }
  .subtitle-sectionDas-aziende {
    font-size: 26px !important;
  }
}

@media (max-width: 768px) {
  .title-sectionDas-aziende {
    font-size: 36px !important;
    font-weight: 600 !important;
  }
  .subtitle-sectionDas-aziende {
    font-size: 22px !important;
  }
}

.container-sectionDas-aziende {
  height: 100% !important;
  margin-top: 120px !important;
  margin-bottom: 120px !important;
}

.container-grafica-sezioneDas-aziende {
  display: flex;
  justify-content: center;
  margin-left: 150px;
}

.text-sectionDas-aziende {
  font-size: 18px !important;
}

.container-text-sectionDas-aziende {
  margin-left: 100px !important;
}

.grafica-sezioneDas-aziende {
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-image: url(../../img/grafica-sezioneDas-aziende.png);
  width: 400px;
  height: 400px;
}

.btn-sectionDas-aziende {
  margin-top: 20px;
  width: 200px !important;
  padding: 10px !important;
  font-weight: bold !important;
  background: linear-gradient(
    84deg,
    rgba(32, 48, 91, 1) 0%,
    rgba(50, 54, 120, 1) 20%,
    rgba(71, 60, 144, 1) 100%
  ) !important;
  color: #ffffff !important;
  border-radius: 0px 20px 20px 0px !important;
  font-size: 18px !important;
  display: flex !important;
  justify-content: center !important;
}

.btn-sectionDas-aziende:hover {
  color: #ffffff !important;
}

@media (max-width: 1200px) {
  .container-grafica-sezioneDas-aziende {
    display: flex;
    justify-content: center;
    margin-left: 10px;
  }
}

@media (max-width: 900px) {
  .container-sectionDas-aziende {
    flex-direction: column !important;
    align-items: center;
    margin-top: 0px !important;
    margin-bottom: 80px !important;
  }

  .container-logo-sectionDas-aziende {
    flex-direction: row;
    justify-content: center;
    gap: 0px;
    margin-bottom: 20px;
  }

  .container-text-sectionDas-aziende {
    margin-left: 0px !important;
    width: 90%;
  }

  .grafica-sezioneDas-aziende {
    width: 300px;
    height: 300px;
    margin-top: -30px;
  }

  .container-text-sectionDas-aziende {
    margin-top: -20px !important;
  }
}
