.Footer {
  margin-top: 8rem;
  background-color: #20305b; //858585
  color: #fff;
  .logowhite {
    color: #fff;
    font-weight: 400;
    font-size: 0.75rem;
    // margin-bottom: 3rem;
  }
}
