.container-header-aziende-sectionText {
  margin-top: 27%;
}
.padding-sectionText-header-aziende {
  padding-left: 10px;
  padding-right: 10px;
}

// text
.title-hero-aziende {
  font-size: 54px !important;
  font-weight: 600 !important;
}

.paragraf-section-2 {
  width: 90%;
  font-size: 18px !important;
  font-weight: 400 !important;
}

// media title
@media (max-width: 1024px) {
  .title-hero-aziende {
    font-size: 48px !important;
  }
}

@media (max-width: 768px) {
  .title-hero-aziende {
    font-size: 36px !important;
  }
}

// image
.cover-image-header-aziende {
  width: 560px !important;
  height: 600px !important;
  margin-left: 0px !important;
}

#lottie-animation-header-aziende {
  position: relative !important;
  top: -30% !important;
  left: 30% !important;
  transform: translateY(50%) !important;
}

// responsive grafica

@media (max-width: 1400px) {
  #lottie-animation-header-aziende {
    left: 20% !important;
  }
}

@media (max-width: 1300px) {
  #lottie-animation-header-aziende {
    left: 15% !important;
  }
}

@media (max-width: 1300px) {
  #lottie-animation-header-aziende {
    left: 10% !important;
  }
}

@media (max-width: 600px) {
  .cover-image-header-aziende {
    width: 260px !important;
    height: 200px !important;
    margin-left: 0px !important;
  }

  #lottie-animation-header-aziende {
    left: 0% !important;
  }
}

// text
.title-header-aziende {
  font-size: 54px !important;
  font-weight: 600 !important;
}
.subtitle-header-aziende {
  font-size: 54px !important;
  font-weight: 700 !important;
}

.paragraf-header-aziende {
  width: 90%;
  font-size: 18px !important;
  font-weight: 400 !important;
}

// button
.btn-header-aziende {
  margin-top: 20px;
  width: 200px !important;
  padding: 10px !important;
  font-weight: bold !important;
  background: linear-gradient(
    84deg,
    rgba(32, 48, 91, 1) 0%,
    rgba(50, 54, 120, 1) 20%,
    rgba(71, 60, 144, 1) 100%
  ) !important;

  color: #ffffff !important;
  border-radius: 0px 20px 20px 0px !important;
  font-size: 18px !important;
  display: flex !important;
  justify-content: center !important;
}

.btn-header-aziende:hover {
  color: #ffffff !important;
}

@media (max-width: 1180px) {
  .cover-image-header-aziende {
    width: 460px !important;
    height: 495px !important;
    margin-left: 0px !important;
  }
}

@media (max-width: 650px) {
  .title-header-aziende {
    font-size: 34px !important;
  }
  .subtitle-header-aziende {
    font-size: 34px !important;
    font-weight: 700 !important;
  }
}

@media (max-width: 1040px) {
  .container-header-aziende-sectionText {
    margin-top: 13%;
  }
  .title-header-aziende {
    font-size: 50px !important;
  }
  .subtitle-header-aziende {
    font-size: 50px !important;
  }
}

@media (max-width: 970px) {
  .padding-sectionText-header-aziende {
    padding-left: 50px;
    margin-top: -50px;
  }
}

@media (max-width: 970px) {
  .container-header-aziende-sectionText {
    margin-top: 50px;
  }
}
@media (max-width: 700px) {
  .padding-sectionText-header-aziende {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 500px) {
  .title-header-aziende {
    font-size: 34px !important;
  }
  .subtitle-header-aziende {
    font-size: 34px !important;
  }
}

@media (max-width: 400px) {
  .container-header-aziende-sectionText {
    margin-top: 20px;
  }
}
